import React, { useState, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import { Rating } from "react-simple-star-rating";
import "./RatingPopup.css";

const RatingPopup = (props) => {
  const [rating, setRating] = useState(0);
  const [description, setDescription] = useState("");
  const [starSize, setStarSize] = useState("55");
  const user = props.forTaker ? "Taker" : "Maker";

  const handleRating = (rate) => {
    setRating(rate);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  useEffect(() => {
    const handleResize = () => {
      const newScreenWidth = window.innerWidth;
      setStarSize(newScreenWidth <= 420 ? "50" : "55");
    };

    window.addEventListener("resize", handleResize);

    // Initial setting of the button text
    setStarSize(window.innerWidth < 420 ? "50" : "55");

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    /* eslint-disable-next-line */
    <Modal
      open={props.open}
      onClose={props.close}
      center
      blockScroll
      classNames={{
        modal: "customModal",
      }}
    >
      <div className="subheader rsv-qty floatleft">Rate the {`${user}`} </div>
      <div className="reserve-header rsv-header floatleft">
        Your rating helps build the JackRabbit Community.{" "}
      </div>

      <div className="rating-popup-rating">
        <Rating
          onClick={handleRating}
          tooltipDefaultText="0"
          transition="true"
          tooltipClassName="tooltip"
          size={starSize}
          allowFraction="true"
          showTooltip="true"
        />
      </div>

      {rating === 5 ? (
        <div className="reserve-header rsv-header">
          {" "}
          Awesome! Share your great experience!{" "}
        </div>
      ) : rating === 4.5 ? (
        <div className="reserve-header rsv-header">
          {" "}
          Nice! Share your experience!{" "}
        </div>
      ) : rating < 3 && rating !== 0 ? (
        <div className="reserve-header rsv-header">
          {" "}
          Were sorry you had a poor experience, please reach out to us!{" "}
        </div>
      ) : (
        <div className="reserve-header rsv-header blank-text"> A </div>
      )}

      <textarea
        className="rating-popup-textarea"
        placeholder="Enter a description (optional)"
        value={description}
        onChange={handleDescriptionChange}
      />

      {rating === 0 ? (
        <button
          type="button"
          className="button-primary wide reserve-btn disabled"
          disabled
        >
          {" "}
          Select a rating{" "}
        </button>
      ) : (
        <button
          type="button"
          className="button-primary wide reserve-btn"
          onClick={() => props.submitRating({ rating, description })}
        >
          {" "}
          Submit Review{" "}
        </button>
      )}
    </Modal>
  );
};

export default RatingPopup;
