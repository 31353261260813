import React from "react";
import MakerListing from "./MakerListing";

function ListingsPopulator(props) {
  function resMapper() {
    if (props.listings === undefined || props.listings.length === 0) {
      return "";
    }
    const listings = props.listings.map((listing) => (
      <MakerListing
        listing={listing}
        key={listing.listing_id}
        setChats={props.setChats}
        updateListing={props.updateListing}
      />
    ));

    return listings;
  }

  return resMapper();
}

export default ListingsPopulator;
