import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Userfront from "@userfront/react";
import ListingContext from "context/ListingContext";
import OpenChat from "pages/Messages/OpenChat";
import ImageDisplayer from "./ListingDetails/ImageDisplayer";
import ProductDetails from "./ListingDetails/ProductDetails";
import ListingHeader from "./ListingHeader";
import UserDetails from "./ListingDetails/UserDetails";
import LocationDetails from "./ListingDetails/LocationDetails";
import UserRelation from "./UserRelation/UserRelation";
import MapBox from "./ListingDetails/MapboxGl";
import { getChat, reservationSuccessAlert } from "../../functions/functions";

function TakerView() {
  const [chat, setChat] = useState(null);
  const { currentListing } = useContext(ListingContext);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    const fetchChat = async () => {
      try {
        const loadedChat = await getChat(
          Userfront.user.userUuid,
          currentListing.maker,
          currentListing.listing_id,
          cancelToken.token
        );
        if (loadedChat.most_recent_message) setChat(loadedChat);
        else setChat(null);
      } catch (error) {
        setChat(null);
      }
    };

    fetchChat();
  }, []);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      reservationSuccessAlert();
    }
  }, []);

  return (
    <>
      <div className="lv-faux-header" />
      <div className="lv-wrapper">
        <ListingHeader />
        <div className="lv-container">
          <div className="lv-left">
            <ImageDisplayer />
            <ProductDetails />
            <UserDetails chat={chat} />
          </div>
          <div className="lv-right">
            <UserRelation />
            <div>
              <LocationDetails taker />
              <MapBox />
            </div>
          </div>
        </div>
      </div>
      <OpenChat
        updateChatOnNewMessage={(updatedChat) => setChat(updatedChat)}
      />
    </>
  );
}
export default TakerView;
