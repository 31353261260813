import React, { useState, useEffect } from "react";
import {
  cancelReservationAlert,
  deleteReservation,
  loadReservations,
  confirmPickupAlert,
  errorMessageAlert,
  completeReservation,
  takerSubmitRating,
  thankYou,
} from "functions/functions";
import RatingPopup from "components/Rating/RatingPopup";
import { LoadingAnimation } from "Business/Animations";
import { useNavigate } from "react-router-dom";
import ReservationPopulator from "./ReservationPopulator";

function TakerReservations() {
  const [data, setData] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [confirmedReservation, setConfirmedReservation] = useState({});
  const [isLoaded, setLoaded] = useState(false);
  const navigate = useNavigate();

  async function updateReservations() {
    const userReservationList =
      JSON.parse(localStorage.getItem("userReservationList")) || [];
    setData(userReservationList);
    setLoaded(true);
  }

  // Removes from the state variable to cause refresh
  // Local storage is slow to update sometimes, so removing it here
  // ensures the change persists on refresh
  function removeLocally(resToDelete) {
    setData(data.filter((res) => res !== resToDelete));
    localStorage.setItem(
      "userReservationList",
      JSON.stringify(data.filter((res) => res !== resToDelete))
    );
  }

  // The head remove function, first waits for user confirmation from cancelReservation sweet alert
  // Then tries to delete it with the api call deleteReservation
  // Then if successful, removes locally
  const removeReservation = async (resToDelete) => {
    const response = await cancelReservationAlert(resToDelete);
    if (response.isConfirmed === true) {
      try {
        await deleteReservation(resToDelete.reservation_id).then(() =>
          removeLocally(resToDelete)
        );
      } catch (error) {
        errorMessageAlert("failed to cancel your reservation");
      }
    }
  };

  // Updates the state variable to cause refresh
  // Local storage is slow to update sometimes, so removing it here
  // ensures the change persists on refresh
  function confirmLocally(resToConfirm) {
    // update the 'is_completed' attribute of the reservation
    const updatedRes = { ...resToConfirm, is_completed: true, state: "" };
    // find the index of the reservation to be updated
    const index = data.findIndex((res) => res === resToConfirm);
    // replace the old reservation with the updated one
    const updatedData = [
      ...data.slice(0, index),
      updatedRes,
      ...data.slice(index + 1),
    ];
    // update the state and local storage
    setData(updatedData);
    localStorage.setItem("userReservationList", JSON.stringify(updatedData));
  }

  // The head confirm function
  // First waits for user confirmation from sweetalert, then confirms the reservation in backend
  // TODO: Add popup to rate maker after confirm
  const confirmPickup = async (resToConfirm) => {
    const response = await confirmPickupAlert(resToConfirm.listing_id.name);
    if (response.isConfirmed === true) {
      try {
        await completeReservation(resToConfirm.reservation_id).then(() =>
          confirmLocally(resToConfirm)
        );
        setConfirmedReservation(resToConfirm);
        setShowPopup(true);
      } catch (error) {
        errorMessageAlert("failed to confirm your pickup");
      }
    }
  };

  // Reservations[0] = all
  // Reservations[1] = upcoming
  // Reservations[2] = current
  // Reservations[3] = completed
  function filterReservations() {
    const now = new Date();
    const all = [];
    const upcoming = [];
    const current = [];
    const completed = [];
    if (data.length === 0) {
      setReservations([[], [], [], []]);
    } else {
      for (let i = 0; i < data.length; i += 1) {
        const res = data[i];
        const pickupStartDate = new Date(res.listing_id.pickup_date_start);

        if (res.is_completed) {
          completed.push(res);
        } else if (pickupStartDate > now) {
          res.state = "upcoming";
          upcoming.push(res);
        } else {
          res.state = "current";
          current.push(res);
        }
        all.push(res);
        setReservations([all, current, upcoming, completed]);
      }
    }
  }

  useEffect(() => {
    loadReservations().then(() => updateReservations());
  }, []);

  useEffect(() => {
    if (data) {
      filterReservations();
    }
  }, [data]);

  const submitRating = async (rating) => {
    try {
      await takerSubmitRating(
        rating,
        confirmedReservation.listing_id.maker,
        confirmedReservation
      );
      setShowPopup(false);
      thankYou();
    } catch (error) {
      errorMessageAlert("failed to post your review");
    }
  };

  function currentReservations() {
    return (
      <div id="Ready" className="reservation-section">
        <div className="rsv-category left">
          {" "}
          Ready for pickup{" "}
          <div className="rsv-category-sub left">
            {reservations[1].length === 0
              ? "No current pickups "
              : `The following listing${
                  reservations[1].length > 1 ? "s are" : " is"
                } available for pickup - Jump on it!`}
          </div>
        </div>
        <ReservationPopulator
          reservations={reservations[1]}
          confirmPickup={confirmPickup}
        />
      </div>
    );
  }

  function upcomingReservations() {
    return (
      <div id="Upcoming" className="reservation-section">
        <div className="rsv-category left">
          {" "}
          Upcoming Pickups{" "}
          <div className="rsv-category-sub left">
            {reservations[2].length === 0
              ? "No upcoming pickups "
              : `The pickup window is approaching for ${
                  reservations[2].length > 1 ? "these listings" : "this listing"
                }, start getting ready!`}
          </div>
        </div>
        <ReservationPopulator
          reservations={reservations[2]}
          removeReservation={removeReservation}
        />
      </div>
    );
  }

  function completedReservations() {
    return (
      <div id="Completed" className="reservation-section">
        <div className="rsv-category left">
          {" "}
          Completed Pickups{" "}
          <div className="rsv-category-sub left">
            {reservations[3].length === 0
              ? "No purchased listings"
              : `You have purchased these listings`}
          </div>
        </div>
        <ReservationPopulator reservations={reservations[3]} />
      </div>
    );
  }

  const handleOptionClick = (optionId) => {
    const reservationSection = document.getElementById(optionId);
    if (reservationSection) {
      const scrollOptions = {
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      };
      const offset = 160; // To adjust for header offset
      const scrollY =
        reservationSection.getBoundingClientRect().top +
        window.pageYOffset -
        offset;
      window.scrollTo({ ...scrollOptions, top: scrollY });
    }
  };

  function sideSelector() {
    return (
      <div className="side-selector">
        {["Ready", "Upcoming", "Completed"].map((optionId, index) => (
          <button
            key={optionId}
            type="button"
            className={`side-option ${
              reservations[index + 1].length === 0 && "side-option-disabled"
            }`}
            onClick={() => handleOptionClick(optionId)}
          >
            {optionId} ({reservations[index + 1].length})
          </button>
        ))}
      </div>
    );
  }

  function renderSwitch() {
    if (isLoaded) {
      return (
        <div className="reservations-container">
          <div
            id="ReservationsHeader"
            className="user-name subheader reservations-header"
          >
            Your Reservations
          </div>
          {data.length > 0 ? (
            <>
              {sideSelector()}

              <div className="reservation-sections">
                {reservations[1].length > 0 && currentReservations()}
                {reservations[2].length > 0 && upcomingReservations()}
                {reservations[3].length > 0 && completedReservations()}
              </div>
            </>
          ) : (
            <div className="reservation-sections">
              <div className="rsv-category left">
                {" "}
                You have no reservations. Check out the{" "}
                <button
                  type="button"
                  className="back listing-bold"
                  onClick={() => navigate("/")}
                >
                  {" "}
                  Marketplace{" "}
                </button>{" "}
                to find something new!
              </div>
            </div>
          )}

          {showPopup && (
            <RatingPopup
              submitRating={(rating) => submitRating(rating)}
              listing={confirmedReservation}
              setShowPopup={setShowPopup}
              forMaker
            />
          )}
        </div>
      );
    }
    return <LoadingAnimation />;
  }

  return <div className="page-container"> {renderSwitch()} </div>;
}

export default TakerReservations;
