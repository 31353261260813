import React from "react";
import CalendarDay from "./CalendarDay";

function CalendarBody({
  date,
  listings,
  showListings,
  reservations,
  showReservations,
  setOpenDay,
}) {
  const startOfMonth = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), 1)
  );
  const endOfMonth = new Date(
    Date.UTC(date.getFullYear(), date.getMonth() + 1, 0)
  );
  const days = [];
  const today = new Date(Date.now());
  today.setHours(0, 0, 0, 0);

  const parseUTCDate = (dateString) => {
    const aDate = new Date(dateString);
    return new Date(
      Date.UTC(aDate.getUTCFullYear(), aDate.getUTCMonth(), aDate.getUTCDate())
    );
  };

  const isSameDay = (date1, date2) => {
    return (
      date1.getUTCDate() === date2.getUTCDate() &&
      date1.getUTCMonth() === date2.getUTCMonth() &&
      date1.getUTCFullYear() === date2.getUTCFullYear()
    );
  };

  const processItems = (items, isListing, currentDate) => {
    return items.reduce((acc, item) => {
      const pickupStartDate = parseUTCDate(
        isListing ? item.pickup_date_start : item.listing_id.pickup_date_start
      );
      const pickupEndDate = parseUTCDate(
        isListing ? item.pickup_date_end : item.listing_id.pickup_date_end
      );

      if (isSameDay(currentDate, pickupStartDate)) {
        acc.push({ ...item, isPickupStart: true });
      }
      if (isSameDay(currentDate, pickupEndDate)) {
        acc.push({ ...item, isPickupStart: false });
      }

      return acc;
    }, []);
  };

  for (let i = 1; i <= startOfMonth.getUTCDay(); i += 1) {
    const previousMonthDay = new Date(
      Date.UTC(
        startOfMonth.getUTCFullYear(),
        startOfMonth.getUTCMonth(),
        -i + 1
      )
    );
    days.unshift(
      <button
        type="button"
        key={`prev-${i}`}
        className="calendar-day other-month"
      >
        <div className="day-number">{previousMonthDay.getUTCDate()}</div>
      </button>
    );
  }

  for (let day = 1; day <= endOfMonth.getUTCDate(); day += 1) {
    const currentDate = new Date(date.getFullYear(), date.getMonth(), day);
    currentDate.setHours(0, 0, 0, 0);

    const openDate = new Date(date.getFullYear(), date.getMonth(), day);
    openDate.setHours(0, 0, 0, 0);

    const isToday = openDate.getTime() === today.getTime();

    let dayListings = [];
    let dayReservations = [];

    if (showListings && listings && listings.length > 0) {
      dayListings = processItems(listings, true, currentDate);
    }

    if (showReservations && reservations && reservations.length > 0) {
      dayReservations = processItems(reservations, false, currentDate);
    }

    days.push(
      <CalendarDay
        key={currentDate.toISOString()}
        openDate={openDate}
        date={day}
        isToday={isToday}
        listings={dayListings}
        reservations={dayReservations}
        setOpenDay={setOpenDay}
      />
    );
  }

  const daysInNextMonthToShow = 6 - endOfMonth.getUTCDay();
  for (let i = 1; i <= daysInNextMonthToShow; i += 1) {
    days.push(
      <button type="button" key={i} className="calendar-day other-month">
        <div className="day-number">{i}</div>
      </button>
    );
  }

  return <div className="calendar-body">{days}</div>;
}

export default CalendarBody;
