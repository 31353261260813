import React from "react";
import { ButtonAnimation } from "Business/Animations";

export function StepConfirm({ answers, submit, loading, setStep }) {
  const clickBack = () => {
    setStep((prev) => prev - 1);
  };

  const titleCase = (s) =>
    s
      .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
      .replace(/[-_]+(.)/g, (_, c) => " ".concat(c.toUpperCase())); // First char after each -/_

  const inputs =
    answers &&
    Object.entries(answers).map(([key, value]) => {
      if (key !== "listing_images") {
        return (
          <tr className="tr" key={key}>
            <td className="header-td bold">{titleCase(key)}</td>
            <td className="small-td light">
              {key === "pickup_date_start" || key === "pickup_date_end"
                ? value.split("T")[0]
                : value}
            </td>
          </tr>
        );
      }
      return null; // Exclude the "photos" attribute
    });
  return (
    <div className="create-listing-container sans" id="step4">
      <div className="reg-label">Does everything look right?</div>
      <table className="reg-table">
        <tbody>{inputs}</tbody>
      </table>
      <div className="button-row">
        <button
          className="button-terciary reserve-confirm-btn left-side"
          onClick={clickBack}
          type="button"
        >
          Back
        </button>
        {loading ? (
          <button
            className="button-primary reserve-confirm-btn right-side"
            type="button"
            disabled
          >
            <ButtonAnimation />
          </button>
        ) : (
          <button
            className="button-primary reserve-confirm-btn right-side"
            type="button"
            onClick={submit}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
}

export default StepConfirm;
