import React, { useContext } from "react";
import UserContext from "context/UserContext";
import AccountOption from "./AccountOption";
import options from "../../Business/options";

function AccountOptions() {
  const { user } = useContext(UserContext);

  return (
    <div className={` ${user?.data?.state === "Maker" ? "mc" : ""} container`}>
      {options().map((option) => (
        <AccountOption key={option.name} option={option} />
      ))}
    </div>
  );
}

export default AccountOptions;
