import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  calendarSvg,
  messagesSvg,
  personSvg,
  manyMessagesSvg,
} from "Business/svg";
import { useMessage } from "context/MessageContext";
import axios from "axios";
import Userfront from "@userfront/react";
import DateChanger from "pages/ListingView/UserRelation/DateChanger";
import {
  dateToString,
  getChat,
  errorMessageAlert,
  getListingChats,
} from "../../../functions/functions";

const NO_IMAGE_URL = process.env.REACT_APP_NO_IMAGE_URL;

function MakerListing({ listing, setChats, updateListing }, props) {
  const { setCurrentChat } = useMessage();
  const [updating, setUpdating] = useState(false);
  const navigate = useNavigate();

  function getImage(listingImages) {
    if (!listingImages || listingImages === "[]") return NO_IMAGE_URL;
    return JSON.parse(listingImages)[0];
  }

  async function fetchListingChats() {
    try {
      const loadedChats = await getListingChats(listing.listing_id);
      setChats(loadedChats);
    } catch (error) {
      setChats(null);
    }
  }

  function listingReservation(reservation) {
    const handleMessageClick = async () => {
      const cancelToken = axios.CancelToken.source();
      try {
        const response = await getChat(
          reservation.taker.uuid,
          Userfront.user.userUuid,
          listing.listing_id,
          cancelToken.token
        );
        setCurrentChat(response);
      } catch (error) {
        if (!axios.isCancel(error)) {
          if (error.response && error.response.status !== 404) {
            errorMessageAlert("failed to message the user");
          }
        }
      }
    };

    return (
      <div className="ml-res">
        <button
          className="ml-res-name"
          type="button"
          onClick={() => navigate(`/user/${reservation?.taker.uuid}`)}
        >
          {personSvg()}
          {`${reservation.taker.first_name} ${reservation.taker.last_name}`}
        </button>
        <div className="ml-res-buttons">
          {reservation.is_completed === true && listing.is_rated === false && (
            <button
              type="button"
              className="ml-res-button rate-taker-button"
              onClick={() => props.rateTaker(reservation)}
            >
              Rate Taker
            </button>
          )}
          <button
            type="button"
            className="ml-res-button"
            onClick={() => handleMessageClick()}
          >
            {messagesSvg()}
          </button>
        </div>
      </div>
    );
  }

  function dateChangingListing() {
    return (
      <div className="ml-container-wrapper">
        <div className="ml-container ml-date-changer">
          <button
            type="button"
            className="maker-listing-image"
            onClick={() => navigate(`/listings/${listing.listing_id}`)}
          >
            <img
              className="listings-image"
              src={getImage(listing.listing_images)}
              alt=""
            />
          </button>
          <div className="ml-right">
            <DateChanger
              currentListing={listing}
              setOpen={() => setUpdating(false)}
              updateListingState={updateListing}
              small
            />
          </div>
        </div>
        {listing.reservations &&
          listing.reservations.length > 0 &&
          listingReservation(listing.reservations[0])}
        {updating && <div />}
      </div>
    );
  }

  function baseListing() {
    return (
      <div className="ml-container-wrapper">
        <div className="ml-container">
          <button
            type="button"
            className="maker-listing-image"
            onClick={() => navigate(`/listings/${listing.listing_id}`)}
          >
            <img
              className="listings-image"
              src={getImage(listing.listing_images)}
              alt=""
            />
          </button>
          <div className="ml-right">
            <button
              className="ml-info"
              type="button"
              onClick={() => navigate(`/listings/${listing.listing_id}`)}
            >
              <div className="ml-name">
                <div className="rsv-helpfultext">item</div>
                <div className="rsv-header">{listing.name}</div>
              </div>
              <div className="ml-dates">
                <div className="rsv-helpfultext">dates </div>
                <div className="rsv-subheader">
                  {" "}
                  <div className="ml-date">
                    {" "}
                    {dateToString(listing.pickup_date_start)}{" "}
                    {`- ${dateToString(listing.pickup_date_end)}`}{" "}
                  </div>{" "}
                </div>
              </div>
            </button>
            <div className="ml-buttons">
              <button
                className="ml-button"
                type="button"
                onClick={() => setUpdating(true)}
              >
                {calendarSvg()}
              </button>
              <button
                className="ml-button strikethrough"
                type="button"
                onClick={() => fetchListingChats()}
              >
                {manyMessagesSvg()}
              </button>
            </div>
          </div>
        </div>
        {listing.reservations &&
          listing.reservations.length > 0 &&
          listingReservation(listing.reservations[0])}
        {updating && <div />}
      </div>
    );
  }

  return updating ? dateChangingListing() : baseListing();
}

export default MakerListing;
