import React, { useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import axios from "axios";
import Userfront from "@userfront/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { ButtonAnimation } from "Business/Animations";
import ReCAPTCHA from "react-google-recaptcha";
import { verifyCaptcha } from "functions/functions";
import "./SignupForm.css";

const API_URL = process.env.REACT_APP_API_ENDPOINT;

const Signup = (props) => {
  const switcher = props.switcher;
  const location = useLocation();
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState("-");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verifiedCaptcha, setVerifiedCaptcha] = useState(false);
  const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
  const recaptchaRef = useRef();

  async function loginUser() {
    try {
      const response = await Userfront.login({
        method: "password",
        email,
        password,
      });
      if (response) {
        if (location.pathname === "/welcome" || props.newUser) navigate("/");
        else window.location.reload();
      }
    } catch (error) {
      setErrMsg(error.message);
      setLoading(false);
    }
  }

  async function createUser() {
    setLoading(true);
    try {
      if (!firstName || !lastName || !email || !password || !confirmPassword) {
        setErrMsg("Please fill in all fields.");
        setLoading(false);
        return;
      }

      if (password !== confirmPassword) {
        setErrMsg("Passwords do not match.");
        setLoading(false);
        return;
      }

      await axios({
        method: "post",
        url: `${API_URL}user/`,
        data: {
          email,
          first_name: firstName,
          last_name: lastName,
          password,
        },
      });
      loginUser();
    } catch (error) {
      setLoading(false);
      setErrMsg(error.response.data.message);
    }
  }
  const onCaptchaChange = async () => {
    const token = recaptchaRef.current.getValue();
    const response = await verifyCaptcha(token);
    if (response) setVerifiedCaptcha(true);
    else setVerifiedCaptcha(false);
  };

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      if (event.target.id === "fn") {
        document.getElementById("ln").focus();
      } else if (event.target.id === "ln") {
        document.getElementById("em").focus();
      } else if (event.target.id === "em") {
        document.getElementById("pw").focus();
      } else if (event.target.id === "pw") {
        document.getElementById("confirmPw").focus();
      } else if (event.target.id === "confirmPw" && verifiedCaptcha)
        createUser();
    }
  }

  function handleChange(event) {
    if (event.target.id === "fn") {
      setFirstName(event.target.value.trim());
    }
    if (event.target.id === "ln") {
      setLastName(event.target.value.trim());
    }
    if (event.target.id === "em") {
      setEmail(event.target.value.trim());
    }
    if (event.target.id === "pw") {
      setPassword(event.target.value.trim());
    }
    if (event.target.id === "confirmPw") {
      setConfirmPassword(event.target.value.trim());
    }
  }

  function switchModal() {
    setErrMsg("-");
    setVerifiedCaptcha(false);
    switcher();
  }

  function close() {
    setErrMsg("-");
    setVerifiedCaptcha(false);
    props.close();
  }

  function getInput(
    id,
    placeholder,
    isPassword = false,
    isConfirmPassword = false
  ) {
    return (
      <div className="input-group">
        <input
          className="signup"
          placeholder={placeholder}
          type={
            isPassword
              ? isConfirmPassword
                ? showConfirmPassword
                  ? "text"
                  : "password"
                : showPassword
                ? "text"
                : "password"
              : "text"
          }
          onKeyDown={(event) => handleKeyDown(event)}
          onChange={(event) => handleChange(event)}
          id={id}
        />
        {isPassword && (
          <div className="in-input">
            <FontAwesomeIcon
              icon={
                isConfirmPassword
                  ? showConfirmPassword
                    ? faEyeSlash
                    : faEye
                  : showPassword
                  ? faEyeSlash
                  : faEye
              }
              onClick={
                isConfirmPassword
                  ? () => setShowConfirmPassword(!showConfirmPassword)
                  : () => setShowPassword(!showPassword)
              }
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <Modal
      open={props.open}
      onClose={() => close()}
      center
      blockScroll
      classNames={{
        modal: "customModal",
      }}
    >
      <div style={{ marginBottom: "2rem" }}>
        <div
          className="subheader rsv-header"
          style={{ marginInline: "auto", paddingInline: "20px" }}
        >
          Welcome to{" "}
          <span style={{ color: "#FF7709", fontWeight: "800" }}>
            JackRabbit
          </span>{" "}
        </div>

        <div
          className="reserve-header rsv-header"
          style={{ textAlign: "center" }}
        >
          We&apos;re excited to meet you!{" "}
        </div>
      </div>

      <div style={{ width: "80%", marginInline: "auto" }}>
        {getInput("fn", "First Name")}
        <div className="faux-header" />
        {getInput("ln", "Last Name")}
        <div className="faux-header" />
        {getInput("em", "email@example.com")}
        <div className="faux-header" />
        {getInput("pw", "Password", true)}
        <div className="faux-header" />
        {getInput("confirmPw", "Confirm Password", true, true)}
        <div
          style={{ textAlign: "center", marginTop: ".5rem" }}
          className={
            errMsg === "-" ? "errorMessage white-text column" : "errorMessage"
          }
        >
          {" "}
          {errMsg}{" "}
        </div>
        <div className="captcha-container">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={reCaptchaKey}
            onChange={onCaptchaChange}
          />
        </div>
        <div style={{ width: "100%", marginTop: "2rem" }} className="column">
          <button
            type="button"
            className="redirectLogin"
            onClick={() => switchModal()}
          >
            Already have an account?
          </button>
          <div className="faux-header" />
        </div>
      </div>
      {loading ? (
        <button type="button" className="button-primary wide" disabled>
          <ButtonAnimation />
        </button>
      ) : (
        <button
          type="button"
          className={`button-primary wide ${
            !verifiedCaptcha ? "disabled" : ""
          }`}
          disabled={!verifiedCaptcha}
          onClick={() => createUser()}
        >
          {" "}
          Sign Up{" "}
        </button>
      )}
    </Modal>
  );
};

export default Signup;
