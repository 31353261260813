import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Userfront from "@userfront/react";

import {
  reauthFromStripeAlert,
  returnFromStripeAlert,
} from "functions/functions";

function StripeReturn(props) {
  const { reauth } = props;
  const navigate = useNavigate();

  // Get User
  useEffect(() => {
    const link = Userfront.user.data ? "/maker-dashboard" : "/";
    if (reauth) {
      reauthFromStripeAlert(() => navigate(link));
    } else {
      returnFromStripeAlert(() => navigate(link));
    }
  }, []);

  return (
    <div className="page-container column">
      <div className="faux-header" />
    </div>
  );
}

export default StripeReturn;
