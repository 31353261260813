import React, { useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Userfront from "@userfront/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./SignupForm.css";
import { ButtonAnimation } from "Business/Animations";
import ReCAPTCHA from "react-google-recaptcha";
import { verifyCaptcha } from "functions/functions";

const Login = (props) => {
  const switcher = props.switcher;
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("-");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verifiedCaptcha, setVerifiedCaptcha] = useState(false);
  const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
  const recaptchaRef = useRef();

  async function loginUser() {
    const isListingPath = () => {
      return location.pathname.startsWith("/listing/");
    };
    setLoading(true);
    try {
      const response = await Userfront.login({
        method: "password",
        email,
        password,
      });
      if (response) {
        if (!isListingPath() && location.pathname !== "/") navigate("/");
        else window.location.reload();
      }
    } catch (error) {
      setErrMsg(error.message);
      setLoading(false);
    }
  }

  const onCaptchaChange = async () => {
    const token = recaptchaRef.current.getValue();
    const response = await verifyCaptcha(token);
    if (response) setVerifiedCaptcha(true);
    else setVerifiedCaptcha(false);
  };

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      if (event.target.id === "em") {
        document.getElementById("pw").focus();
      } else loginUser();
    }
  }

  function handleChange(event) {
    setErrMsg("-");
    if (event.target.id === "em") {
      setEmail(event.target.value.trim());
    }
    if (event.target.id === "pw") {
      setPassword(event.target.value.trim());
    }
  }

  function getInput(id, placeholder, isPassword = false) {
    return (
      <div className="input-group">
        <input
          className="signup"
          placeholder={placeholder}
          type={isPassword ? (showPassword ? "text" : "password") : "text"}
          onKeyDown={(event) => handleKeyDown(event)}
          onChange={(event) => handleChange(event)}
          id={id}
        />
        {isPassword && (
          <div className="in-input">
            <FontAwesomeIcon
              icon={showPassword ? faEyeSlash : faEye}
              onClick={() => setShowPassword(!showPassword)}
            />
          </div>
        )}
      </div>
    );
  }

  function switchModal() {
    setErrMsg("-");
    switcher();
  }

  function close() {
    setErrMsg("-");
    props.close();
  }

  return (
    <Modal
      open={props.open}
      onClose={() => close()}
      center
      blockScroll
      classNames={{
        modal: "customModal",
      }}
    >
      <div>
        <div
          className="subheader rsv-header login-header"
          style={{ marginInline: "auto", marginTop: "1rem" }}
        >
          Welcome back to{" "}
          <span style={{ color: "#FF7709", fontWeight: "800" }}>
            JackRabbit
          </span>{" "}
        </div>
        <div
          className="reserve-header rsv-header"
          style={{ textAlign: "center" }}
        >
          We&apos;re glad you&apos;re back!{" "}
        </div>
      </div>
      <div style={{ width: "80%", marginTop: "2rem", marginInline: "auto" }}>
        {getInput("em", "email@example.com")}
        <div className="faux-header" />
        {getInput("pw", "Password", true)}
        <div
          style={{ textAlign: "center", marginTop: ".5rem" }}
          className={
            errMsg === "-" ? "errorMessage white-text" : "errorMessage"
          }
        >
          {" "}
          {errMsg}{" "}
        </div>
        <div className="captcha-container captcha-login">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={reCaptchaKey}
            onChange={onCaptchaChange}
          />
        </div>
      </div>
      <div style={{ width: "100%", marginTop: "2rem" }} className="column">
        <button
          type="button"
          className="redirectLogin"
          onClick={() => switchModal()}
        >
          Don&apos;t have an account?
        </button>
        <div className="faux-header" />
        {loading ? (
          <button type="button" className="button-primary wide" disabled>
            <ButtonAnimation />
          </button>
        ) : (
          <button
            type="button"
            className={`button-primary wide ${
              !verifiedCaptcha ? "disabled" : ""
            }`}
            disabled={!verifiedCaptcha}
            onClick={() => loginUser()}
          >
            {" "}
            Log In{" "}
          </button>
        )}
      </div>
    </Modal>
  );
};

export default Login;
