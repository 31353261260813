import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ListingContext from "context/ListingContext";
import {
  deleteListing,
  deleteListingAlert,
  deleteListingSuccessAlert,
  errorMessageAlert,
  cantDeleteListingAlert,
} from "functions/functions";
import { trashSvg } from "Business/svg";

function DeleteListing({ setActive }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { currentListing } = useContext(ListingContext);
  const { name } = currentListing;
  const id = currentListing.listing_id;

  const afterDelete = async () => {
    deleteListingSuccessAlert();
    navigate("/your-listings");
  };

  const promptDelete = async () => {
    const response = await deleteListingAlert(name);
    if (response.isConfirmed) {
      try {
        await deleteListing(id);
        afterDelete();
      } catch (error) {
        errorMessageAlert("failed to delete your listing");
      }
    } else setOpen(false);
  };

  const deleteCheck = () => {
    setActive("Trash");
    setOpen(true);
    if (currentListing.isReserved) cantDeleteListingAlert();
    else promptDelete();
  };

  return (
    <button
      type="button"
      className={`ltb-button ${open ? `ltb-active` : ""}`}
      onClick={() => deleteCheck()}
    >
      {trashSvg()}
    </button>
  );
}

export default DeleteListing;
