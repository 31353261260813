import { useContext } from "react";
import ListingContext from "context/ListingContext";

function ListingDates() {
  const { currentListing } = useContext(ListingContext);

  const showDate = (date) => {
    return <div className="show-date">{date}</div>;
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "20px",
        width: "80%",
      }}
    >
      <div className="date-enterer">
        <div className="rsv-helpfultext"> Start </div>
        {showDate(currentListing.dates[0])}
      </div>

      <div className="date-enterer">
        <div className="rsv-helpfultext"> End </div>
        {showDate(currentListing.dates[1])}
      </div>
    </div>
  );
}

export default ListingDates;
