import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import {
  errorMessageAlert,
  getMessages,
  getUser,
  sendMessage,
  sendMessageEmail,
} from "functions/functions";
import Userfront from "@userfront/react";

const MessageContext = createContext();

export function useMessage() {
  return useContext(MessageContext);
}

export function MessageProvider({ children }) {
  const [currentChat, setCurrentChat] = useState(null);
  const [otherPerson, setOtherPerson] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchUser = async () => {
      const idToGet =
        Userfront.user.userUuid === currentChat.maker
          ? currentChat.taker
          : currentChat.maker;
      try {
        const userData = await getUser(idToGet);
        setOtherPerson(userData);
      } catch (error) {
        errorMessageAlert("failed to load user data");
      }
    };

    const fetchMessages = async () => {
      try {
        setMessages([]);
        const response = await getMessages(currentChat.id);
        setMessages(response);
      } catch (error) {
        errorMessageAlert("failed to load your chat");
      }
    };

    if (currentChat) {
      fetchMessages();
      fetchUser();
    }
  }, [currentChat]);

  const trySendMessage = async (messageData, callback) => {
    let respData;
    try {
      respData = await sendMessage(currentChat.id, messageData);
      setMessages((prevMessages) => [...prevMessages, respData.message_data]);
      if (callback) {
        const updatedChat = {
          ...currentChat,
          most_recent_message: respData.message_data,
        };
        callback(updatedChat);
      }
    } catch (error) {
      errorMessageAlert("failed to send your message");
      throw error;
    }
    try {
      if (respData) {
        await sendMessageEmail(respData);
      }
    } catch (emailError) {
      // eslint-disable-next-line
      console.error("Error sending email:", emailError);
    }
  };

  const value = useMemo(
    () => ({
      currentChat,
      setCurrentChat,
      messages,
      trySendMessage,
      otherPerson,
    }),
    [currentChat, messages, trySendMessage]
  );

  return (
    <MessageContext.Provider value={value}>{children}</MessageContext.Provider>
  );
}
