import React, { useState, useEffect } from "react";
import {
  AddressAutofill,
  useConfirmAddress,
  config,
} from "@mapbox/search-js-react";

const Step22 = ({
  answers,
  errors,
  editAnswers,
  setStep,
  isValid,
  setErrors,
  newLocation,
  setLocation,
  autoFilled,
}) => {
  const mapboxKey = process.env.REACT_APP_MAPBOX_TOKEN;
  config.accessToken = mapboxKey;
  const [editedLocation, setEditedLocation] = useState(false);
  const { formRef, showConfirm } = useConfirmAddress({
    minimap: true,
    skipConfirmModal: () => false,
  });
  const clickBack = () => {
    setStep((prev) => prev - 1);
  };
  const clickNext = async () => {
    if (!autoFilled || editedLocation) {
      const result = await showConfirm();
      if (result.type !== "cancel") {
        setStep((prev) => prev + 1);
      }
    } else if (isValid(answers, setErrors)) {
      setStep((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (newLocation.line1 !== "")
      editAnswers(
        "location",
        `${newLocation.line1}${
          newLocation.line2 !== "" ? ` ${newLocation.line2}` : ""
        }, ${newLocation.level2} ${newLocation.level1}, ${newLocation.postal}`
      );
  }, [newLocation]);
  function locationChange(e) {
    setEditedLocation(true);
    setLocation((prev) => ({ ...prev, line1: e.target.value }));
  }

  return (
    <form
      className="create-listing-container sans"
      id="step2"
      ref={formRef}
      style={{ marginBottom: "80px" }}
    >
      <AddressAutofill accessToken={mapboxKey}>
        <div className="reg-label" htmlFor="location">
          Where is the pickup location?
        </div>

        <div className="reg-label-2"> Address </div>
        <input
          className="reg-input"
          id="street-adress"
          autoComplete="address-line1"
          onChange={(e) => locationChange(e)}
          value={
            autoFilled && !editedLocation ? answers.location : newLocation.line1
          }
        />
        {(!autoFilled || editedLocation) && (
          <>
            <div className="reg-label-2"> Address Line 2 </div>
            <input
              className="reg-input"
              id="street-adress-2"
              autoComplete="address-line2"
              // autoComplete="shipping adress-line1 address-line2"
              // editAnswers( "location", e.target.value)
              onChange={(e) =>
                setLocation((prev) => ({ ...prev, line2: e.target.value }))
              }
              value={newLocation.line2}
            />

            <div className="reg-label-2"> City </div>
            <input
              className="reg-input"
              id="city"
              autoComplete="address-level2"
              // autoComplete="shipping adress-line1 address-line2"
              // editAnswers( "location", e.target.value)
              onChange={(e) =>
                setLocation((prev) => ({ ...prev, level2: e.target.value }))
              }
              value={newLocation.level2}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "49%" }}>
                <div className="reg-label-2"> State / Region </div>
                <input
                  className="reg-input"
                  id="state"
                  autoComplete="address-level1"
                  // autoComplete="shipping adress-line1 address-line2"
                  // editAnswers( "location", e.target.value)
                  onChange={(e) =>
                    setLocation((prev) => ({
                      ...prev,
                      level1: e.target.value,
                    }))
                  }
                  value={newLocation.level1}
                />
              </div>
              <div style={{ width: "49%" }}>
                <div className="reg-label-2"> Zip / Postcode </div>
                <input
                  className="reg-input"
                  id="zip"
                  autoComplete="postal-code"
                  // autoComplete="shipping adress-line1 address-line2"
                  // editAnswers( "location", e.target.value)
                  onChange={(e) =>
                    setLocation((prev) => ({
                      ...prev,
                      postal: e.target.value,
                    }))
                  }
                  value={newLocation.postal}
                />
              </div>
            </div>
          </>
        )}
      </AddressAutofill>
      <div className="reg-label-2" htmlFor="description">
        Pickup Instructions
      </div>
      <textarea
        className="reg-text-area small-text-area"
        id="description"
        onChange={(e) => editAnswers("pickup_instructions", e.target.value)}
        placeholder="Optional"
        value={answers.pickup_instructions}
      />
      <div className="error-message">{errors.location}</div>

      <div className="button-row">
        <button
          className="button-terciary reserve-confirm-btn left-side"
          type="button"
          onClick={clickBack}
        >
          Back
        </button>
        <button
          className="button-primary reserve-confirm-btn right-side"
          type="button"
          onClick={clickNext}
        >
          Continue
        </button>
      </div>
    </form>
  );
};

export default Step22;
