import CalendarInfo from "./CalendarInfo";

function CalendarHeader(props) {
  const { date, setDate, resObj, listingObj } = props;

  function dateChanger() {
    const buttonClick = (id) => {
      let dateAsObj = new Date(date);
      dateAsObj.setDate(1);

      if (id === "back") {
        dateAsObj.setMonth(dateAsObj.getMonth() - 1);
      }
      if (id === "forward") {
        dateAsObj.setMonth(dateAsObj.getMonth() + 1);
      }
      if (id === "today") {
        dateAsObj = new Date();
      }

      setDate(dateAsObj);
    };

    const backSvg = () => {
      return (
        <svg
          width="25"
          height="25"
          viewBox="0 0 33 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.4508 18.69C0.221093 17.5895 0.221098 14.4101 2.4508 13.3096L27.7666 0.815205C29.7604 -0.168797 32.0943 1.28204 32.0943 3.5054V28.4942C32.0943 30.7176 29.7604 32.1684 27.7666 31.1844L2.4508 18.69Z"
            fill="#484848"
          />
        </svg>
      );
    };

    const forwardSvg = () => {
      return (
        <svg
          width="25"
          height="25"
          viewBox="0 0 31 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28.7597 13.3275C30.9424 14.4407 30.9424 17.5593 28.7597 18.6725L4.4007 31.0954C2.4047 32.1134 0.037735 30.6635 0.037735 28.4229V3.57709C0.037735 1.33649 2.40469 -0.113379 4.4007 0.90457L28.7597 13.3275Z"
            fill="#484848"
          />
        </svg>
      );
    };

    const circleSvg = () => {
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8" cy="8" r="8" fill="#484848" />
        </svg>
      );
    };

    return (
      <div className="date-changer">
        <button
          id="back"
          className="date-changer-button"
          type="button"
          onClick={() => buttonClick("back")}
        >
          {backSvg()}
        </button>
        <button
          className="date-changer-button"
          type="button"
          onClick={() => buttonClick("today")}
        >
          {circleSvg()}
        </button>
        <button
          id="forward"
          className="date-changer-button"
          type="button"
          onClick={() => buttonClick("forward")}
        >
          {forwardSvg()}
        </button>
      </div>
    );
  }

  function displayDate() {
    const monthYear = date.toLocaleString("default", {
      month: "long",
      year: "numeric",
    });
    return <div className="date-text">{monthYear}</div>;
  }

  return (
    <div className="calendar-header">
      <div className="mobile-calendar-header">
        {displayDate()}
        <CalendarInfo resObj={resObj} listingObj={listingObj} />
      </div>
      {dateChanger()}
    </div>
  );
}

export default CalendarHeader;
