import React from "react";
import ReactDOM from "react-dom/client";
import { ListingProvider } from "context/ListingContext";
import { UserProvider } from "context/UserContext";
import { ViewTransitionProvider } from "pages/ViewTransition";
import { ListingsProvider } from "./context/ListingsContext";
import { RouteContextProvider } from "./context/RouteContext";
import { MessageProvider } from "./context/MessageContext";
import "./index.css";
import Router from "./Router";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouteContextProvider>
      <UserProvider>
        <ListingProvider>
          <ListingsProvider>
            <MessageProvider>
              <ViewTransitionProvider>
                <Router />
              </ViewTransitionProvider>
            </MessageProvider>
          </ListingsProvider>
        </ListingProvider>
      </UserProvider>
    </RouteContextProvider>
  </React.StrictMode>
);
