import React, { useContext } from "react";
import ListingContext from "context/ListingContext";

function ListingHeader() {
  const { currentListing } = useContext(ListingContext);

  return (
    <div className="item-header">
      <div className="item-name floatleft"> {currentListing.name} </div>
    </div>
  );
}

export default ListingHeader;
