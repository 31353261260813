import React from "react";
import ImageSelector from "./ImageSelector";

export const Step32 = ({
  answers,
  errors,
  editAnswers,
  setStep,
  isValid,
  setErrors,
}) => {
  const clickBack = () => {
    setStep((prev) => prev - 1);
  };

  const clickNext = () => {
    if (isValid(answers, setErrors)) {
      setStep((prev) => prev + 1);
    }
  };

  return (
    <div className="create-listing-container sans" id="step3">
      <div className="reg-label"> Market your listing </div>

      <div className="reg-label-2 file-label">
        Add photos ({answers.listing_images.length} of 6)
      </div>
      <ImageSelector
        answers={answers.listing_images}
        editAnswers={editAnswers}
      />
      <div className="error-message">{errors.images}</div>
      <div className="reg-label-2">
        {" "}
        Good photos showcase your product and give Takers confidence in their
        purchase{" "}
      </div>
      <div className="button-row">
        <button
          className="button-terciary reserve-confirm-btn left-side"
          onClick={clickBack}
          type="button"
        >
          Back
        </button>
        <button
          className="button-primary reserve-confirm-btn right-side"
          type="button"
          onClick={clickNext}
        >
          Continue
        </button>
      </div>
    </div>
  );
};
export default Step32;
