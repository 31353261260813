import React, { useState, useEffect } from "react";

const Step21 = ({
  answers,
  errors,
  editAnswers,
  setStep,
  isValid,
  setErrors,
  autoFilled,
}) => {
  const clickBack = () => {
    setStep((prev) => prev - 1);
  };

  const clickNext = async () => {
    if (isValid(answers, setErrors)) {
      setStep((prev) => prev + 1);
    }
  };

  const [activeButton, setIsActiveOne] = useState("");
  const [endButton, setEndButton] = useState("");

  const handleClick = (button) => {
    setIsActiveOne(button);
    if (button === "yes") {
      const today = new Date();
      const startDate = today.toISOString().split("T")[0];
      editAnswers("pickup_date_start", startDate);
    }
    if (button === "no") {
      editAnswers("pickup_date_start", "");
    }
  };

  const handleClickEnd = (button) => {
    setEndButton(button);
    if (button === "yes1") editAnswers("pickup_date_end", "");
    if (button === "no1") {
      const startDate = new Date(answers.pickup_date_start);
      const endDate = new Date(
        startDate.getTime() + 3 * 30 * 24 * 60 * 60 * 1000
      );
      const endDateFormatted = endDate.toISOString().split("T")[0];
      editAnswers("pickup_date_end", endDateFormatted);
    }
  };
  const isStartDatePassed = () => {
    const givenDatetime = new Date(answers.pickup_date_start);
    const currentDatetime = new Date();
    return givenDatetime < currentDatetime;
  };

  useEffect(() => {
    if (answers.pickup_date_start !== "")
      if (isStartDatePassed()) handleClick("yes");
      else handleClick("no");
    if (answers.pickup_date_end !== "") handleClickEnd("no1");
  }, [autoFilled]);

  return (
    <div
      className="create-listing-container sans"
      id="step2"
      style={{ marginBottom: "80px" }}
    >
      <div className="reg-label">When is your listing available?</div>
      <div style={{ width: "80%", marginBottom: "30px" }}>
        {/* TODO: allow user to select from saved Sites to autofill the address */}
        <div
          className="reg-label-2"
          id="1-1"
          style={{ color: activeButton === "no" ? "#b5b4b4" : "" }}
          htmlFor="pickup_date_start"
        >
          Is it readily available?{" "}
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "15px",
            marginBottom: "20px",
            marginTop: "13px",
          }}
        >
          <div
            className="row"
            style={{ gap: "15px", opacity: activeButton === "no" ? ".5" : "1" }}
          >
            <button
              type="button"
              className={`qty-btn ${
                activeButton === "yes" ? "qty-btn-active" : ""
              }`}
              id="yes"
              onClick={() => handleClick("yes")}
              style={{ marginTop: "5px" }}
            >
              Yes
            </button>
            <div className="row">
              <button
                type="button"
                className={`qty-btn ${
                  activeButton === "no" ? "qty-btn-active" : ""
                }`}
                id="no"
                onClick={() => handleClick("no")}
                style={{ marginTop: "5px" }}
              >
                No
              </button>
            </div>
          </div>
        </div>
        {activeButton === "no" && (
          <div className="reg-label-2" id="1-2" htmlFor="pickup_date_start">
            When will it be available?{" "}
          </div>
        )}
        {activeButton === "no" && (
          <input
            className="reg-input date-input"
            id="pickup_date_start"
            type="date"
            min={new Date().toISOString().split("T")[0]}
            value={
              answers.pickup_date_start
                ? answers.pickup_date_start.substring(0, 10)
                : ""
            }
            onChange={(e) => {
              // Q: Why not using e.target.value?
              // A: e.target.value is in users' timezone,
              //    to change it to UTC (store in backend, so that everyone agrees),
              //    add new Date().getTimezoneOffset(),
              //    which is the device offset
              const userTime = new Date(e.target.value);
              userTime.setMinutes(
                userTime.getMinutes() + new Date().getTimezoneOffset()
              );
              editAnswers("pickup_date_start", userTime.toISOString());
            }}
          />
        )}
        {/* TODO: allow user to select from saved Sites to autofill the address */}
        {answers.pickup_date_start && (
          <>
            <div
              className="reg-label-2"
              id="1-1"
              style={{ color: endButton === "yes1" ? "#b5b4b4" : "" }}
              htmlFor="pickup_date_start"
            >
              Does this availability end?{" "}
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "15px",
                marginBottom: "20px",
                marginTop: "13px",
              }}
            >
              <div
                className="row"
                style={{
                  gap: "15px",
                  opacity: endButton === "yes1" ? ".5" : "1",
                }}
              >
                <button
                  type="button"
                  className={`qty-btn ${
                    endButton === "yes1" ? "qty-btn-active" : ""
                  }`}
                  id="yes1"
                  onClick={() => handleClickEnd("yes1")}
                  style={{ marginTop: "5px" }}
                >
                  Yes
                </button>
                <div className="row">
                  <button
                    type="button"
                    className={`qty-btn ${
                      endButton === "no1" ? "qty-btn-active" : ""
                    }`}
                    id="no1"
                    onClick={() => handleClickEnd("no1")}
                    style={{ marginTop: "5px" }}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
            {endButton === "yes1" && (
              <div className="reg-label-2" id="1-2" htmlFor="pickup_date_start">
                When does it end?{" "}
              </div>
            )}
            {endButton === "yes1" ? (
              <input
                className="reg-input date-input"
                id="pickup_date_start"
                type="date"
                min={new Date().toISOString().split("T")[0]}
                value={
                  answers.pickup_date_end
                    ? answers.pickup_date_end.substring(0, 10)
                    : ""
                }
                onChange={(e) => {
                  // Q: Why not using e.target.value?
                  // A: e.target.value is in users' timezone,
                  //    to change it to UTC (store in backend, so that everyone agrees),
                  //    add new Date().getTimezoneOffset(),
                  //    which is the device offset
                  const userTime = new Date(e.target.value);
                  userTime.setMinutes(
                    userTime.getMinutes() + new Date().getTimezoneOffset()
                  );
                  editAnswers("pickup_date_end", userTime.toISOString());
                }}
              />
            ) : endButton === "no1" ? (
              <div className="reg-label-2">
                {" "}
                Your listing will be active for 3 months, until{" "}
                {new Date(answers.pickup_date_end).toLocaleDateString(
                  undefined,
                  {
                    month: "long",
                    day: "numeric",
                  }
                )}
              </div>
            ) : (
              ""
            )}
          </>
        )}
        <div className="error-messsage">{errors.pickup_date_start}</div>
        <div className="error-message">{errors.pickup_date_end}</div>
      </div>
      <div className="button-row">
        <button
          className="button-terciary reserve-confirm-btn left-side"
          type="button"
          onClick={clickBack}
        >
          Back
        </button>
        <button
          className="button-primary reserve-confirm-btn right-side"
          type="button"
          onClick={clickNext}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default Step21;
