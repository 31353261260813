import React, { useState, useEffect } from "react";
import { getUser, formatDateMonthYear } from "functions/functions";
import "./ProfileReviews.css";
import { LoadingAnimation } from "Business/Animations";

function reviewPopulator(review, image) {
  return (
    <div className="review-card" key={review.rating_id}>
      <div className="top row" style={{ gap: "20px" }}>
        <div className="rating-image-container">
          <img className="rating-image" src={image} alt="" />
        </div>

        <div className="header">
          {review.rater_name ? review.rater_name : "No Name"}
          <div className="date-created">
            {formatDateMonthYear(review.date_created)}
          </div>
        </div>
      </div>

      <div className="description">{review.description}</div>
    </div>
  );
}

function ProfileReviews(props) {
  const [takerImages, setTakerImages] = useState([]);
  const reviews = props.reviews;
  const state = props.state;
  const loaded = props.loaded;

  useEffect(() => {
    const fetchTakerImages = async () => {
      if (reviews[state].data && reviews.length > 0) {
        const takerIds = reviews[state].data.map((review) => review.rater);

        try {
          const usersData = await Promise.all(
            takerIds.map((id) => getUser(id))
          );
          const images = usersData.map((userData) => userData.image);
          setTakerImages(images);
        } catch (error) {
          setTakerImages([]);
        }
      }
    };

    fetchTakerImages();
  }, [reviews]);

  function reviewDisplayer() {
    if (reviews[state].data.length === 0)
      return (
        <div className="left">
          {" "}
          {state === 0
            ? "This user has no reviews as a Maker..."
            : "This user has no reviews as a Taker..."}{" "}
        </div>
      );
    return reviews[state].data.map((review, index) =>
      reviewPopulator(review, takerImages[index])
    );
  }

  function renderSwitch() {
    switch (loaded) {
      case true:
        return reviewDisplayer();
      default:
        return <LoadingAnimation />;
    }
  }

  return <>{renderSwitch()}</>;
}

export default ProfileReviews;
