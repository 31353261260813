import Userfront from "@userfront/react";

Userfront.init("9nywgrgn");

const Signup = Userfront.build({
  toolId: "rbkorm",
});

const Login = Userfront.build({
  toolId: "akormd",
});

export function SignupForm() {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Signup />
    </div>
  );
}

export function LoginForm() {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Login />
    </div>
  );
}
