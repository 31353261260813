import {
  calendarSvg,
  messagesSvg,
  listingSvg,
  reservationsSvg,
  paymentPayoutSvg,
  notificationSvg,
  loginSecuritySvg,
} from "Business/svg";
import { useContext } from "react";
import UserContext from "context/UserContext";

export default function options() {
  const { user } = useContext(UserContext);

  const accOptions = [
    {
      name: "Calendar",
      desc: "View and manage your listings and reservations",
      svg: calendarSvg(),
      url: "/calendar",
    },
    {
      name: "Messages",
      desc: "View current and past messages",
      svg: messagesSvg(),
      url: "/messages",
    },
  ];

  if (user.data?.state === "Maker") {
    accOptions.push({
      name: "Listings",
      desc: "View and manage current and past Listings",
      svg: listingSvg(),
      url: "/your-listings",
    });
  } else
    accOptions.push({
      name: "Reservations",
      desc: "View and manage current and past reservations",
      svg: reservationsSvg(),
      url: "/your-reservations",
    });

  accOptions.push(
    {
      name: "Payment & payouts",
      desc: "Review payments, payouts, and financial history",
      svg: paymentPayoutSvg(),
      disabled: true,
    },
    {
      name: "Notifications",
      desc: "Manage notification preferences and methods of contact",
      svg: notificationSvg(),
      disabled: true,
    },
    {
      name: "Login & security",
      desc: "Manage your account and change your password",
      svg: loginSecuritySvg(),
      url: "/account-security",
    }
  );

  return accOptions;
}
