import { useNavigate } from "react-router-dom";

function AccountOption({ option }) {
  const { name, desc, svg, url, disabled } = option;
  const navigate = useNavigate();

  return (
    <button
      type="button"
      className={`same-item ${disabled ? "same-item-disabled" : ""}`}
      onClick={() => navigate(url)}
    >
      <div className="text-container2">
        {svg}
        <p className="bold cp">{name}</p>
        <p className="custom-p">{desc}</p>
      </div>
    </button>
  );
}

export default AccountOption;
