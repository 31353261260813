import ListingsPopulator from "pages/MakerDashboard/MakerListings/ListingsPopulator";
import Userfront from "@userfront/react";
import { useNavigate } from "react-router-dom";
import RatingPopup from "components/Rating/RatingPopup";
import OpenChat from "pages/Messages/OpenChat";
import React, { useState, useEffect } from "react";
import {
  cancelResAlertMaker,
  deleteReservation,
  errorMessageAlert,
  loadMakerListings,
  makerSubmitRating,
  markListingRated,
  reservationDeleteAlert,
  thankYou,
} from "functions/functions";
import { LoadingAnimation } from "Business/Animations";
import NewListingButton from "components/NewListingButton";
import MakerListingsTopBar from "./MakerListingsTopbar";
import MessagesOpen from "../../ListingView/MessagesOpen";

function MakerListings() {
  const navigate = useNavigate();
  const [listings, setListings] = useState();
  const [filteredListings, setFiltered] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [resToBeRated, setReservation] = useState({});
  const [isLoaded, setLoaded] = useState(false);
  const [viewPast, setViewPast] = useState(false);
  const userUuid = Userfront.user.userUuid;
  const [chats, setChats] = useState(false);

  const updateListing = (updatedListing) => {
    setListings(
      listings.map((listing) =>
        listing.listing_id === updatedListing.listing_id
          ? updatedListing
          : listing
      )
    );
  };

  const fetchListings = async () => {
    try {
      const data = await loadMakerListings(userUuid);
      setListings(data);
    } catch (error) {
      errorMessageAlert(`failed to load your listings`);
    }
  };

  const cancelReservation = async (resToDelete) => {
    const response = await cancelResAlertMaker(resToDelete);
    if (response.isConfirmed === true) {
      try {
        await deleteReservation(resToDelete.reservation_id)
          .then(() => fetchListings())
          .then(() => reservationDeleteAlert());
      } catch (error) {
        errorMessageAlert(
          `failed to cancel ${resToDelete.taker.first_name}'s reservation`
        );
      }
    }
  };

  function filterListings() {
    const now = new Date();
    const all = [];
    const current = [];
    const upcoming = [];
    const unreserved = [];
    const completed = [];
    const needsReview = [];

    for (let i = 0; i < listings.length; i += 1) {
      const listing = listings[i];
      const pickupStartDate = new Date(listing.pickup_date_start);
      if (listing.reservations.length === 0) {
        unreserved.push(listing);
        all.push(listing);
      } else if (listing.reservations[0].is_completed) {
        if (!listing.is_rated) {
          listing.state = "needsReview";
          needsReview.push(listing);
        } else {
          listing.state = "completed";
          completed.push(listing);
        }
      } else if (pickupStartDate > now) {
        listing.state = "upcoming";
        upcoming.push(listing);
      } else {
        listing.state = "current";
        current.push(listing);
      }
      all.push(listing);
      setFiltered([all, current, upcoming, unreserved, completed, needsReview]);
    }
  }

  useEffect(() => {
    if (userUuid) fetchListings();
  }, []);

  useEffect(() => {
    if (listings) {
      if (listings.length > 0) filterListings();
      else setLoaded(true);
    }
  }, [listings]);

  const rateTaker = (reservation) => {
    setReservation(reservation);
    setShowPopup(true);
  };

  const markListingRatedLocal = (listingId) => {
    const indexToUpdate = listings.findIndex(
      (item) => item.listing_id === listingId
    );
    if (indexToUpdate !== -1) {
      const updatedListings = [...listings];
      updatedListings[indexToUpdate].is_rated = true;
      setListings(updatedListings);
    } else {
      errorMessageAlert(`failed to update your listing.`);
    }
  };

  const submitRating = async (rating) => {
    try {
      await makerSubmitRating(rating, resToBeRated);
      setShowPopup(false);
      thankYou();
      try {
        markListingRated(resToBeRated.listing_id);
        markListingRatedLocal(resToBeRated.listing_id);
      } catch (error) {
        errorMessageAlert("failed to mark your listing as rated");
      }
    } catch (error) {
      errorMessageAlert("failed to post your review");
    }
  };

  useEffect(() => {
    if (filteredListings) setLoaded(true);
  }, [filteredListings]);

  function needReview() {
    return (
      <div id="Needs" className="reservation-section">
        {" "}
        <div className="rsv-category left">
          {" "}
          Needs Review ({filteredListings[5].length}){" "}
          <div className="rsv-category-sub left">
            {filteredListings[5].length === 1
              ? "Please leave a review for this listing"
              : "Please leave reviews for these listings "}
          </div>
        </div>
        <div className="reservations">
          <ListingsPopulator
            listings={filteredListings[5]}
            rateTaker={rateTaker}
            updateListing={updateListing}
          />
        </div>
      </div>
    );
  }

  function readyListings() {
    return (
      <div id="Ready" className="reservation-section">
        {" "}
        <div className="rsv-category left">
          {" "}
          Ready for Pickup{" "}
          <div className="rsv-category-sub left">
            {filteredListings[1].length === 0
              ? "No listings open for pickup "
              : "The pickup window is open and the Taker will soon be coming to pick up the material - please have it ready!"}
          </div>
        </div>
        <div className="reservations">
          <ListingsPopulator
            listings={filteredListings[1]}
            setChats={setChats}
            updateListing={updateListing}
          />
        </div>
      </div>
    );
  }

  function upcomingListings() {
    return (
      <div id="Upcoming" className="reservation-section">
        {" "}
        <div className="rsv-category left">
          {" "}
          Upcoming{" "}
          <div className="rsv-category-sub left">
            {filteredListings[2].length === 0
              ? "No upcoming listings"
              : "These listings are reserved and their pickup window is approaching - please keep them up to date"}
          </div>
        </div>
        <div className="reservations">
          <ListingsPopulator
            listings={filteredListings[2]}
            cancelReservation={cancelReservation}
            setChats={setChats}
            updateListing={updateListing}
          />
        </div>
      </div>
    );
  }

  function unreservedListings() {
    return (
      <div id="Unreserved" className="reservation-section">
        {" "}
        <div className="rsv-category left">
          {" "}
          Unreserved{" "}
          <div className="rsv-category-sub left">
            {filteredListings[3].length === 0
              ? "No unreserved listings"
              : "These listings are unreserved and still active on the marketplace"}
          </div>
        </div>
        <div className="reservations">
          <ListingsPopulator
            listings={filteredListings[3]}
            setChats={setChats}
            updateListing={updateListing}
          />
        </div>
      </div>
    );
  }

  function completedListings() {
    return (
      <div id="Completed" className="reservation-section">
        {" "}
        <div className="rsv-category left">
          {" "}
          Completed{" "}
          <div className="rsv-category-sub left">
            {filteredListings[4].length === 0
              ? "No completed listings"
              : "These listings have been completed"}
          </div>
        </div>
        <div className="reservations">
          <ListingsPopulator
            listings={filteredListings[4]}
            setChats={setChats}
            updateListing={updateListing}
          />
        </div>
      </div>
    );
  }

  const handleOptionClick = (optionId) => {
    const reservationSection = document.getElementById(optionId);
    if (reservationSection) {
      const scrollOptions = {
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      };
      const offset = 160; // To adjust for header offset
      const scrollY =
        reservationSection.getBoundingClientRect().top +
        window.pageYOffset -
        offset;
      window.scrollTo({ ...scrollOptions, top: scrollY });
    }
  };

  function sideSelector() {
    return (
      <div className="side-selector">
        {filteredListings[5] && filteredListings[5].length > 0 && (
          <button
            type="button"
            className="side-option "
            onClick={() => handleOptionClick("Needs")}
          >
            Needs Review ({filteredListings[5].length})
          </button>
        )}

        {["Ready", "Upcoming", "Unreserved"].map((optionId, index) => (
          <button
            key={optionId}
            type="button"
            className={`side-option ${
              filteredListings[index + 1].length === 0 && "side-option-disabled"
            }`}
            onClick={() => handleOptionClick(optionId)}
          >
            {optionId} ({filteredListings[index + 1].length})
          </button>
        ))}
      </div>
    );
  }

  function noListings(bool) {
    return (
      <div className="reservation-sections">
        <div className="rsv-category zindex700 left">
          {" "}
          {`You have no ${
            bool ? "completed" : ""
          } listings. If you have something to sell,`}{" "}
          <button
            type="button"
            className="back listing-bold"
            onClick={() => navigate("/create-listing")}
          >
            {" "}
            List it now!{" "}
          </button>{" "}
        </div>
      </div>
    );
  }
  function renderSwitch() {
    if (isLoaded)
      return (
        <>
          <div className="reservations-container listings-container">
            {listings.length > 0 ? (
              <>
                {!viewPast && sideSelector()}
                <div className="reservation-sections">
                  <div className="reservation-sections">
                    {viewPast ? (
                      filteredListings[4]?.length > 0 ? (
                        completedListings()
                      ) : (
                        noListings(true)
                      )
                    ) : (
                      <>
                        {filteredListings[5]?.length > 0 && needReview()}
                        {filteredListings[1]?.length > 0 && readyListings()}
                        {filteredListings[2]?.length > 0 && upcomingListings()}
                        {filteredListings[3]?.length > 0 &&
                          unreservedListings()}
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              noListings(false)
            )}
          </div>
          {showPopup && (
            <RatingPopup
              submitRating={(rating) => submitRating(rating)}
              reservation={resToBeRated}
              setShowPopup={setShowPopup}
              forTaker
              open={showPopup}
            />
          )}
        </>
      );
    return <LoadingAnimation />;
  }

  return (
    <>
      <MakerListingsTopBar active={viewPast} setActive={setViewPast} />
      {renderSwitch()}
      <NewListingButton />
      <MessagesOpen open={chats} close={() => setChats(null)} chats={chats} />
      <OpenChat />
    </>
  );
}

export default MakerListings;
