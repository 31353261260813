const editErrors = (setErrors, field, value) => {
  setErrors((prev) => ({
    ...prev,
    [field]: value,
  }));
};

const Step31Validation = (answers, setErrors) => {
  let isValid = true;
  if (answers.title === "") {
    editErrors(setErrors, "title", "Please provide a title.");
    isValid = false;
  }
  return isValid;
};

const Step32Validation = (answers, setErrors) => {
  let isValid = true;
  if (answers.listing_images.length === 0) {
    editErrors(setErrors, "images", "Please select at least one image.");
    isValid = false;
  }
  return isValid;
};

const Step21Validation = (answers, setErrors) => {
  let isValid = true;
  if (answers.pickup_date_start === "") {
    editErrors(setErrors, "pickup_date_start", "Please select a start date.");
    isValid = false;
  }
  if (answers.pickup_date_end === "") {
    editErrors(setErrors, "pickup_date_end", "Please select an end date.");
    isValid = false;
  } else {
    const maxPickUpDate = new Date(answers.pickup_date_start);
    maxPickUpDate.setMonth(maxPickUpDate.getMonth() + 3);
    if (new Date(answers.pickup_date_end) > maxPickUpDate) {
      editErrors(
        setErrors,
        "pickup_date_end",
        "End date needs to be within 3-month of start date."
      );
      isValid = false;
    }
  }
  return isValid;
};

const Step22Validation = (answers, setErrors) => {
  let isValid = true;
  if (answers.location === "") {
    editErrors(setErrors, "location", "Please provide a pickup location.");
    isValid = false;
  }
  return isValid;
};

const Step11Validation = (answers, setErrors) => {
  let isValid = true;
  if (answers.material === "") {
    editErrors(setErrors, "material", "Please select a category.");
    isValid = false;
  } else {
    editErrors(setErrors, "material", "");
  }
  if (answers.condition === "") {
    editErrors(setErrors, "condition", "Please select a condition.");
    isValid = false;
  } else {
    editErrors(setErrors, "condition", "");
  }
  return isValid;
};
const Step12Validation = (answers, setErrors) => {
  let isValid = true;
  if (
    answers.quantity < 1 ||
    !Number.isInteger(Number.parseFloat(answers.quantity, 10))
  ) {
    editErrors(setErrors, "quantity", "Please input a quantity.");
    isValid = false;
  } else {
    editErrors(setErrors, "quantity", "");
  }
  if (
    !answers.total_price ||
    answers.total_price < 0 ||
    answers.total_price > 999999
  ) {
    editErrors(
      setErrors,
      "total_price",
      "Please input a price ($1 - $999,999)"
    );
    isValid = false;
  } else {
    editErrors(setErrors, "total_price", "");
  }
  return isValid;
};

export {
  Step11Validation,
  Step12Validation,
  Step21Validation,
  Step22Validation,
  Step31Validation,
  Step32Validation,
};
