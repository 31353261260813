import {
  Body,
  Container,
  Head,
  Html,
  Img,
  Preview,
  Section,
  Text,
  Row,
  Button,
  Column,
} from "@react-email/components";
import * as React from "react";

const imgUrl =
  "https://jackrabbitpictures.s3.amazonaws.com/photos/Jackrabbit-text.png";

const main = {
  backgroundColor: "#ffffff",
  color: "#24292e",
  fontFamily:
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
};

const container = {
  width: "480px",
  margin: "0 auto",
  padding: "20px 0 48px",
};

const section = {
  padding: "24px",
  border: "solid 1px #dedede",
  borderRadius: "5px",
  textAlign: "center",
  minWidth: "350px",
};

const text = {
  margin: "0 0 10px 0",
  textAlign: "left",
};

const quoted = {
  ...text,
  textAlign: "left",
};

const footer = {
  color: "#6a737d",
  fontSize: "12px",
  textAlign: "center",
  marginTop: "60px",
};

const button = {
  fontSize: "14px",
  backgroundColor: "#ff7708",
  color: "#ffffff",
  lineHeight: 1.5,
  borderRadius: "0.5em",
  width: "auto",
  padding: "0.75em 1.5em",
  fontWeight: "600",
};

const userImage = {
  marginBottom: "16px",
  borderRadius: "50%",
};

export const NewMessage = ({ obj }) => (
  <Html>
    <Head />
    <Preview>Message from {obj.sender_first_name} </Preview>
    <Body style={main}>
      <Container style={container}>
        <Img src={imgUrl} width="180" height="25" alt="JSM" />
        <br />
        <Text style={text}>
          Hey <strong>{obj.recipient_first_name}</strong>, you have a new
          message!
        </Text>
        <Section style={section}>
          <Section>
            <Row>
              <Column style={{ width: "55px" }}>
                <Img
                  src={obj.sender_url}
                  width="50"
                  height="50"
                  alt=""
                  style={userImage}
                />
              </Column>
              <Column align="left">
                <Text style={text}>{obj.sender_first_name}</Text>
              </Column>
            </Row>
          </Section>
          <Text style={quoted}> &quot;{obj.message_data.content}&quot;</Text>
          <br />
          <br />
          <Button style={button} href="www.jackrabt.com/messages">
            View Messages
          </Button>
        </Section>
        <Text style={footer}>Jackrabbit Salvage Marketplace</Text>
      </Container>
    </Body>
  </Html>
);

export default NewMessage;
