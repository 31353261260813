import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./Search.css";
import ListingsContext from "context/ListingsContext";

function Search() {
  const { searchInput, alteredSearch, loadListings } =
    useContext(ListingsContext);
  const navigate = useNavigate();

  function execute() {
    alteredSearch(document.getElementById("search").value.trim());
    navigate("/");
  }

  function handleKeyDown(event) {
    if (event.keyCode === 13) {
      execute();
    }
  }

  function clearSearch() {
    loadListings();
    document.getElementById("search").value = "";
  }

  return (
    <div className="search-container">
      <input
        id="search"
        className="searchbar"
        type="search"
        placeholder="Search"
        aria-label="Search"
        name="search"
        onKeyDown={(event) => handleKeyDown(event)}
      />
      {searchInput && (
        <button
          className="button-terciary search-variant"
          type="button"
          onClick={() => clearSearch()}
        >
          {" "}
          x{" "}
        </button>
      )}
      <button
        className="search"
        type="submit"
        onClick={() => {
          execute();
        }}
      >
        {" "}
        <div className="search-icon">
          <svg
            width="28"
            height="22"
            viewBox="0 0 40 30"
            fill="#FF7708"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="22" cy="10" r="10" fill="white" />
            <line
              x1="18.7134"
              y1="14.8205"
              x2="1.71341"
              y2="30.8205"
              stroke="white"
              strokeWidth="5"
            />
            <circle cx="22" cy="10" r="6" fill="#FF7708" />
          </svg>
        </div>
      </button>
    </div>
  );
}

export default Search;
