import ListingsPopulator from "pages/MakerDashboard/MakerListings/ListingsPopulator";
import { dateToString } from "functions/functions";
import ReservationPopulator from "pages/TakerDashboard/TakerReservations/ReservationPopulator";
import { motion, AnimatePresence } from "framer-motion";

function CalendarDayOpen(props) {
  const { openDay, setOpenDay } = props;
  const variants = {
    hidden: { x: "100%" },
    visible: {
      x: "0%",
      transition: {
        duration: 0.4,
        type: "tween",
        stiffness: 300,
        ease: "easeInOut",
      },
    },
  };

  if (openDay) {
    return (
      <AnimatePresence>
        <motion.div
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={variants}
          className="calendar-day-open"
          layout
        >
          <div className="open-day-row">
            <div className="day-header-open">
              {dateToString(openDay.openDate)}
            </div>
            <button
              type="button"
              className="close-btn"
              onClick={() => setOpenDay(false)}
            >
              {" "}
              x{" "}
            </button>
          </div>
          {openDay.listings.length > 0 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div className="open-day-subheader"> Listings </div>
              <ListingsPopulator
                listings={openDay.listings}
                setChats={props.setChats}
                updateListing={props.updateListing}
              />
            </div>
          )}
          {openDay.reservations.length > 0 && (
            <>
              <div className="open-day-subheader"> Reservations </div>
              <ReservationPopulator reservations={openDay.reservations} />
            </>
          )}
        </motion.div>
      </AnimatePresence>
    );
  }
}
export default CalendarDayOpen;
