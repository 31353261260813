import React from "react";
import PasswordChanger from "./PasswordChanger";

function LoginSecurity() {
  return (
    <div className="page-container">
      <PasswordChanger />
    </div>
  );
}

export default LoginSecurity;
