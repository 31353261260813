import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { getListing } from "functions/functions";
import RouteContext from "context/RouteContext";
import ListingContext from "context/ListingContext";
import { LoadingAnimation } from "Business/Animations";
import BreadCrumb from "components/Breadcrumb/BreadCrumb";
import ViewDecider from "./ViewDecider";
import "./ListingView.css";
import "./UserRelation/UserRelation";

function ListingView() {
  const { updateConditionalText } = useContext(RouteContext);
  const { updateListingState } = useContext(ListingContext);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state

  const { id: listingId } = useParams();

  async function fetchListing() {
    try {
      const newListing = await getListing(listingId);
      updateListingState(newListing);
      updateConditionalText(newListing.name);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchListing();
  }, []);

  return (
    <>
      {loading ? (
        <div className="page-container listing-page-container">
          <div className="faux-header" />
          <LoadingAnimation />
        </div>
      ) : error ? (
        <div className="page-container listing-page-container">
          <div className="faux-header" />
          <div>No listing found</div>
        </div>
      ) : (
        <>
          <BreadCrumb />
          <ViewDecider />
        </>
      )}
      <br />
    </>
  );
}

export default ListingView;
