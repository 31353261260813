import React, { useContext, useEffect } from "react";
import { switchSvg } from "Business/svg";
import UserContext from "context/UserContext";
import { useNavigate } from "react-router-dom";
import { errorMessageAlert, updateUserState } from "functions/functions";
import { useViewTransition } from "pages/ViewTransition";
import DropDownOption from "./DropdownOption";

function ViewSwitcher({ close }) {
  const { user, updateUser } = useContext(UserContext);
  const otherView = user?.data?.state === "Maker" ? "Taker" : "Maker";
  const { setAnimation } = useViewTransition();
  const navigate = useNavigate();

  function changeTheme(newState) {
    const root = document.documentElement;
    const variables = [
      "orange",
      "orange-hover",
      "gray-line",
      "gray",
      "light-gray",
      "disabled",
      "box-shadow",
      "input-radius",
      "input-background",
      "background",
      "border-radius-large",
      "border-radius-medium",
      "border-radius-small",
      "actual-terciary",
      "orange-or-black",
      "white-or-black",
      "o-w-hover",
    ];

    variables.forEach((varName) => {
      root.style.setProperty(`--${varName}`, `var(--${newState}--${varName})`);
    });
  }

  useEffect(() => {
    changeTheme(user?.data?.state);
  }, [user?.data?.state]);

  async function changeState() {
    const data = user.data;
    setAnimation(data.state);
    let url;
    if (data.state === "Maker") {
      data.state = "Taker";
      url = "/";
    } else {
      data.state = "Maker";
      url = "/dashboard";
    }
    try {
      await updateUserState(data);
      navigate(url);
      updateUser();
      close();
    } catch (error) {
      errorMessageAlert("failed to switch your view");
    }
  }

  return (
    <DropDownOption
      key="switch"
      option={{
        name: `Switch to ${otherView}`,
        svg: switchSvg(),
      }}
      clickHandler={() => changeState()}
    />
  );
}

export default ViewSwitcher;
