function MakerListingsTopBar(props) {
  const { active, setActive } = props;

  return (
    <div className="listing-top-bar">
      <div className="ltb-container">
        <button
          type="button"
          className="ltb-button ltb-main"
          onClick={() => setActive("home")}
        >
          Your Listings
        </button>

        <div className="ltb-buttons">
          <button
            type="button"
            className={`ltb-button ${active === false ? `ltb-active` : ""}`}
            onClick={() => setActive(false)}
          >
            Current
          </button>
          <button
            type="button"
            className={`ltb-button ${active === true ? `ltb-active` : ""}`}
            onClick={() => setActive(true)}
          >
            Completed
          </button>
        </div>
      </div>
    </div>
  );
}

export default MakerListingsTopBar;
