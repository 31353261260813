import React, { useContext, useEffect, useRef } from "react";
import ListingsContext from "context/ListingsContext";
import { locationSplit, capitalizer } from "./ListingFunctions";

const NO_IMAGE_URL = process.env.REACT_APP_NO_IMAGE_URL;

function ListingPopulator({ allListings, navigator, activeId }) {
  const cardRefs = useRef([]);
  const { searchInput, listingStatus, filtersChanged } =
    useContext(ListingsContext);

  // TODO: Store no_listing_image locally?
  function getImage(listingImages) {
    if (!listingImages || listingImages === "[]") return NO_IMAGE_URL;
    return JSON.parse(listingImages)[0];
  }
  function getDate(listingDate) {
    const currentDate = new Date(); // Get the current date
    const listingStartDate = new Date(listingDate); // Convert the listing start date to a Date object

    if (listingStartDate <= currentDate) {
      return "Now";
    }
    return new Date(listingDate).toLocaleDateString(undefined, {
      month: "short",
      day: "numeric",
    });
  }

  useEffect(() => {
    cardRefs.current = allListings.map(
      (_, i) => cardRefs.current[i] ?? React.createRef()
    );
    const activeIndex = allListings.findIndex(
      (listing) => listing.listing_id === activeId
    );
    const activeCardRef = cardRefs.current[activeIndex];

    if (activeCardRef && activeCardRef.current) {
      activeCardRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, [activeId, allListings]);

  function listingCard(result, index) {
    return (
      <div
        className={`listing-container ${
          result.is_reserved ? "reserved-listing" : ""
        }`}
        key={result.listing_id}
        ref={cardRefs.current[index]}
        id={index}
        role="presentation"
        onClick={() => navigator(result)}
      >
        <div className="thumbnail-container">
          <img
            className="listings-image"
            src={getImage(result.listing_images)}
            alt=""
          />
          <div className="upper-corner">
            <div className="upper">
              {/* svg of a clock */}
              <svg
                width="15"
                height="15"
                viewBox="0 0 31 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.8378 8.085C16.8378 7.24222 16.1546 6.55901 15.3118 6.55901C14.4691 6.55901 13.7859 7.24222 13.7859 8.085V15.7149C13.7859 16.5577 14.4691 17.2409 15.3118 17.2409H21.4158C22.2586 17.2409 22.9418 16.5577 22.9418 15.7149C22.9418 14.8721 22.2586 14.1889 21.4158 14.1889H16.8378V8.085Z"
                  fill="#FF8A00"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M30.5717 15.7149C30.5717 24.1427 23.7396 30.9748 15.3118 30.9748C6.88407 30.9748 0.052002 24.1427 0.052002 15.7149C0.052002 7.28714 6.88407 0.455078 15.3118 0.455078C23.7396 0.455078 30.5717 7.28714 30.5717 15.7149ZM27.5197 15.7149C27.5197 22.4571 22.0541 27.9228 15.3118 27.9228C8.56962 27.9228 3.10397 22.4571 3.10397 15.7149C3.10397 8.9727 8.56962 3.50705 15.3118 3.50705C22.0541 3.50705 27.5197 8.9727 27.5197 15.7149Z"
                  fill="#FF8A00"
                />
              </svg>{" "}
              <span style={{ color: "black" }}>
                {getDate(result.pickup_date_start)}{" "}
              </span>
            </div>

            <div className="upper">
              {" "}
              <span style={{ color: "black" }}>
                {locationSplit(result.location)}
              </span>
              <svg
                width="10"
                height="15"
                viewBox="0 0 10 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.0276 5.91222C9.0276 8.34659 8.23255 10.8375 7.14204 12.5703C6.10656 14.2156 3.89463 14.2156 2.85915 12.5703C1.76864 10.8375 0.973587 8.34659 0.973587 5.91222C0.973587 2.03819 2.55413 0.212891 5.00059 0.212891C7.44705 0.212891 9.0276 2.03819 9.0276 5.91222Z"
                  fill="#FF8A00"
                />
                <ellipse
                  cx="5.00014"
                  cy="4.59726"
                  rx="2.4162"
                  ry="2.63046"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>

        <img className="listing-image" src={result.image} alt="" />

        <div className="listing-body">
          <div className="text-container">
            <div className="header"> {capitalizer(result.name)} </div>

            <div>
              <div className="qty">
                {" "}
                <div className="inline space rsv-helpfultext italic small">
                  {" "}
                  qty
                </div>
                {result.quantity}{" "}
              </div>
              <div> ${result.unit_price} </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function mapToCards() {
    return allListings.map((result, index) => {
      return listingCard(result, index);
    });
  }

  function renderSwitch() {
    if (allListings.length === 0) {
      if (filtersChanged || listingStatus || searchInput) {
        return (
          <div className="reservations-container">
            {" "}
            <div className="profile-header">No listings found</div>{" "}
            <div className="rsv-helpfultext left">
              {" "}
              No listings match your entered criteria{" "}
            </div>{" "}
          </div>
        );
      }
      return (
        <div className="reservations-container">
          {" "}
          <div className="profile-header">The marketplace is empty</div>{" "}
          <div className="rsv-helpfultext left">
            {" "}
            There are no listings to display{" "}
          </div>{" "}
        </div>
      );
    }

    return mapToCards();
  }

  return renderSwitch();
}

export default ListingPopulator;
