import React, { useEffect, useContext, useState } from "react";
import Listings from "pages/Marketplace/Listings/Listings";
import MapView from "pages/Marketplace/Listings/MapView";
import FilterBar from "pages/Marketplace/Topbar/FilterBar";
import ListingsContext from "context/ListingsContext";
import Userfront from "@userfront/react";
import Landing from "./Landing";

function ListingsPage() {
  const { loadListings } = useContext(ListingsContext);
  const [visited, setVisited] = useState(false);
  const [view, setView] = useState(true);

  const viewSetter = () => {
    setView(!view);
  };
  const setVisitedLocalStorage = () => {
    localStorage.setItem("visited", "true");
    setVisited(true);
  };

  useEffect(() => {
    const checkVisited = () => {
      const visitedValue = localStorage.getItem("visited");
      if (visitedValue === "true" || Userfront.tokens.accessToken) {
        setVisited(true);
      }
    };

    checkVisited();
  }, []);

  useEffect(() => {
    loadListings();
  }, []);

  return !visited ? (
    <Landing setVisited={setVisitedLocalStorage} />
  ) : (
    <>
      <FilterBar viewSetter={viewSetter} />
      {view ? (
        <MapView />
      ) : (
        <div className="page-container">
          {" "}
          <Listings />{" "}
        </div>
      )}
    </>
  );
}

export default ListingsPage;
