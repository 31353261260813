import { useState } from "react";
import {
  badDateInput,
  errorMessageAlert,
  changePickupTime,
  successChangeAlert,
} from "functions/functions";

function DateChanger({ currentListing, setOpen, updateListingState, small }) {
  const [pickupStart, setStart] = useState(
    new Date(currentListing.pickup_date_start).toISOString().split("T")[0]
  );
  const [pickupEnd, setEnd] = useState(
    new Date(currentListing.pickup_date_end).toISOString().split("T")[0]
  );

  function checkInput() {
    let err = false;

    if (pickupEnd < pickupStart) err = true;
    return err;
  }

  async function submitUpdate() {
    try {
      await changePickupTime(currentListing.listing_id, pickupStart, pickupEnd);
      updateListingState({
        ...currentListing,
        pickup_date_start: pickupStart,
        pickup_date_end: pickupEnd,
      });
      successChangeAlert();
      setOpen();
    } catch (error) {
      errorMessageAlert("failed to change your listings pickup window");
    }
  }

  function handleClick() {
    if (!checkInput()) submitUpdate();
    else badDateInput();
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: small ? "flex-start" : "",
          alignSelf: small ? "flex-start" : "",
          gap: "20px",
          width: "80%",
        }}
      >
        <div className="date-enterer">
          <div className="rsv-helpfultext"> Start </div>
          <input
            className="reg-input date-input up-time date-enterer dei"
            type="date"
            value={pickupStart}
            onChange={(e) => setStart(e.target.value)}
            min={new Date().toISOString().split("T")[0]}
          />
        </div>
        <div className="date-enterer">
          <div className="rsv-helpfultext"> End </div>
          <input
            className="reg-input date-input up-time date-enterer dei"
            type="date"
            value={pickupEnd}
            onChange={(e) => setEnd(e.target.value)}
            min={new Date().toISOString().split("T")[0]}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: small ? "flex-end" : "",
          gap: small ? "10px" : "20px",
          width: "100%",
          height: small ? "35px" : "50px",
          marginTop: small ? "" : "30px",
        }}
      >
        <button
          type="button"
          className={`button-primary  reserve-btn bold ${
            small ? "small-btn" : ""
          }`}
          style={{ width: small ? "" : "50%" }}
          onClick={() => handleClick()}
        >
          {" "}
          Save{" "}
        </button>
        <button
          type="button"
          className={`button-terciary  reserve-btn bold ${
            small ? "small-btn" : ""
          }`}
          style={{ width: small ? "" : "50%" }}
          onClick={() => setOpen(false)}
        >
          {" "}
          Cancel
        </button>
      </div>
    </>
  );
}

export default DateChanger;
