import UserContext from "context/UserContext";
import { useContext } from "react";

function CalendarInfo(props) {
  const { user } = useContext(UserContext);
  const { resObj, listingObj } = props;

  function itemButton(obj, listing) {
    return (
      <button
        type="button"
        className={`
            cal-filter
            ${obj.active ? "cal-filter-active" : ""}
            ${listing ? "orange-filter" : ""}
            `}
        onClick={() => obj.change()}
      >
        {obj.text}
      </button>
    );
  }

  return (
    <div className="cal-filter-row">
      {user?.data?.state === "Maker"
        ? itemButton(listingObj, true)
        : itemButton(resObj)}
    </div>
  );
}

export default CalendarInfo;
