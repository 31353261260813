function DropDownOption({ option, clickHandler }) {
  const { name, svg } = option;

  return (
    <button
      type="button"
      className="dropdown-item"
      onClick={() => clickHandler()}
    >
      <div className="dropdown-icon">{svg}</div>
      <p className="dropdown-text bold">{name} </p>
    </button>
  );
}

export default DropDownOption;
