import React, { useContext } from "react";
import UserContext from "context/UserContext";
import NewListingButton from "components/NewListingButton";
import AccountOptions from "./AccountOptions";
import AccountHeader from "./AccountHeader/AccountHeader";
import "./AccountOptions.css";

function Account() {
  const { user, loadedUser } = useContext(UserContext);

  if (loadedUser) {
    return (
      <div className="page-container column">
        <div className="faux-header" />
        <div className="user-bar-container">
          <AccountHeader
            user={user}
            state={0}
            self
            reviewCount={user.data.maker_review_count}
          />
          <AccountOptions />
        </div>
        {user.data.isMaker && <NewListingButton />}
      </div>
    );
  }
}

export default Account;
