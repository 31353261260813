import "./Footer.css";

function Footer() {
  return (
    <div className="footer-container">
      <div className="rsv-helpfultext center">
        For questions, comments, or concerns please email info@jackrabt.com
      </div>
    </div>
  );
}

export default Footer;
