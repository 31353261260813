import { useState, useContext } from "react";
import ListingContext from "context/ListingContext";
import DateChanger from "./DateChanger";
import ListingDates from "./ListingDates";

function DateChangerWrapper() {
  const [open, setOpen] = useState(false);
  const { currentListing, updateListingState } = useContext(ListingContext);

  return (
    <div className="info-container">
      <div>
        <div className="rsv-header left"> Current Availability </div>
      </div>

      {open ? (
        <DateChanger
          currentListing={currentListing}
          setOpen={setOpen}
          updateListingState={updateListingState}
        />
      ) : (
        <>
          <ListingDates />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              width: "100%",
              height: "50px",
              marginTop: "30px",
            }}
          >
            <button
              type="button"
              className="button-terciary  reserve-btn bold"
              onClick={() => setOpen(true)}
              style={{ width: "100%" }}
            >
              {" "}
              Change Availability
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default DateChangerWrapper;
