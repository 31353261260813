import React, { useContext } from "react";
import UserContext from "context/UserContext";
import "./UserRating.scss";

import { Rating } from "react-simple-star-rating";

function UserRating(props) {
  const { user } = useContext(UserContext);
  const rating =
    user?.data?.state === "Maker"
      ? user?.data?.maker_rating
      : user?.data?.taker_rating;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        gap: "5px",
      }}
    >
      <div>
        {props.self && (
          <div className="rating-topper" style={{ textAlign: "right" }}>
            {" "}
            Your {user?.data?.state} Rating{" "}
          </div>
        )}
        <div className="rating-holder">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div className="secondary-subheader margin-bottom-5">
              {rating === undefined ? "NA" : rating.toFixed(2)}
            </div>
            <Rating
              size="30px"
              readonly="true"
              initialValue={rating}
              allowFraction="true"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserRating;
