import React from "react";
import { useNavigate } from "react-router-dom";
import { dateRegex } from "pages/Marketplace/Listings/ListingFunctions";
import "./Reservation.css";

const NO_IMAGE_URL = process.env.REACT_APP_NO_IMAGE_URL;

function getImage(listingImages) {
  if (!listingImages || listingImages === "[]") return NO_IMAGE_URL;
  return JSON.parse(listingImages)[0];
}

function ReservationPopulator(props) {
  const navigate = useNavigate();

  const longEnUSFormatter = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  function resMapper() {
    if (props.reservations === undefined || props.reservations.length === 0) {
      return "";
    }
    const reservations = props.reservations.map((result) => {
      return (
        <button
          className="reservation-container"
          key={result.reservation_id}
          type="button"
          onClick={() => navigate(`/listings/${result.listing_id.listing_id}`)}
        >
          <div className="reservation-row row1" style={{ width: "100%" }}>
            <div className="reservation-row" style={{ gap: "20px" }}>
              <div className="maker-listing-image">
                <img
                  className="listings-image"
                  src={getImage(result.listing_id.listing_images)}
                  alt=""
                />
              </div>

              <div className="column">
                <div className="rsv-helpfultext  left">
                  item{" "}
                  <div className="rsv-header" style={{ display: "inline" }}>
                    {" "}
                    {result.listing_id.name}{" "}
                  </div>
                </div>
                <div className="reservation-body">
                  <div className="reservation-details">
                    <div className="rsv-helpfultext  left">
                      quantity{" "}
                      <div className="rsv-header" style={{ display: "inline" }}>
                        {" "}
                        {result.quantity_requested}{" "}
                      </div>
                    </div>
                    <div className="rsv-helpfultext  left">
                      address{" "}
                      <div
                        className="rsv-subheader"
                        style={{ display: "inline" }}
                      >
                        {" "}
                        {result.listing_id.location}{" "}
                      </div>
                    </div>
                    <div className="rsv-helpfultext  left">
                      current window{" "}
                      <div
                        className="rsv-subheader"
                        style={{ display: "inline" }}
                      >
                        {" "}
                        {longEnUSFormatter.format(
                          new Date(
                            dateRegex(result.listing_id.pickup_date_start)[0]
                          )
                        )}{" "}
                        -{" "}
                        {longEnUSFormatter.format(
                          new Date(
                            dateRegex(result.listing_id.pickup_date_end)[0]
                          )
                        )}{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="reservation-buttons">
              {/* Can only cancel if the reservation is upcoming */}
              {result.state === "upcoming" ? (
                <button
                  type="button"
                  className="reservation-button"
                  onClick={(event) => {
                    event.stopPropagation(); // Prevent event propagation
                    props.removeReservation(result);
                  }}
                >
                  {" "}
                  Cancel{" "}
                </button>
              ) : (
                ""
              )}
              {/* Can only mark as completed if the reservation is current */}
              {result.state === "current" ? (
                <button
                  type="button"
                  className="reservation-button reservation-button-orange"
                  onClick={(event) => {
                    event.stopPropagation(); // Prevent event propagation
                    props.confirmPickup(result);
                  }}
                >
                  {" "}
                  Confirm Pickup{" "}
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </button>
      );
    });
    return <div className="reservations"> {reservations} </div>;
  }

  return <>{resMapper()}</>;
}

export default ReservationPopulator;
