import React, { useEffect, useContext, useState } from "react";
import ListingContext from "context/ListingContext";
import UserContext from "context/UserContext";
import UserIsReserved from "./UserIsReserved";
import AnotherUserReserved from "./AnotherUserReserved";
import CompletedListing from "./CompletedListing";
import ReservationFlow from "./ReservationFlow/ReservationFlow";
import ExpiredListing from "./ExpiredListing";

function UserRelation(props) {
  const { currentListing, listingState } = useContext(ListingContext);
  const { user } = useContext(UserContext);
  const pickupStart = props.pickupStart;
  const pickupEnd = props.pickupEnd;
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (currentListing.reservations) {
      setLoading(false);
    }
  }, [currentListing.reservations]);

  if (isLoading) {
    return <div className="reserve-container" />;
  }

  let componentToRender = null;

  if (
    currentListing.reservations[0] &&
    currentListing.reservations[0].is_completed
  ) {
    componentToRender = (
      <CompletedListing
        isMaker={listingState.isOwner}
        isTaker={listingState.isReserved}
        isRated={currentListing.is_rated}
        listingId={currentListing.listing_id}
        reservation={currentListing.reservations[0]}
      />
    );
  } else if (
    (currentListing?.reservations[0] &&
      currentListing?.reservations[0]?.taker?.uuid === user.userUuid) ||
    listingState.isReserved
  ) {
    componentToRender = <UserIsReserved />;
  } else if (currentListing.is_reserved) {
    componentToRender = <AnotherUserReserved />;
  } else if (currentListing.isAvailble === false)
    componentToRender = <ExpiredListing />;
  else {
    componentToRender = (
      <ReservationFlow pickupStart={pickupStart} pickupEnd={pickupEnd} />
    );
  }

  return componentToRender;
}

export default UserRelation;
