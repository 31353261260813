import React from "react";
import Filter from "./Filter";
import "./Filters.css";

function FilterBar() {
  const materialOptions = [
    { value: "any", label: "Any", key: "any" },
    { value: "general", label: "General", key: "general" },
    { value: "concrete", label: "Concrete", key: "concrete" },
    { value: "electrical", label: "Electrical", key: "electrical" },
    { value: "finishes", label: "Finishes", key: "finishes" },
    { value: "masonry", label: "Masonry", key: "masonry" },
    { value: "mechanical", label: "Mechanical", key: "mechanical" },
    { value: "metals", label: "Metals", key: "metals" },
    { value: "plastics", label: "Plastics", key: "plastics" },
    { value: "tools", label: "Tools", key: "tools" },
    { value: "woods", label: "Woods", key: "woods" },
    { value: "other", label: "Other", key: "other" },
  ];

  const availabilityOptions = [
    { value: "any", label: "Any", key: "any" },
    { value: "now", label: "Now", key: "now" },
    { value: "later", label: "Later", key: "later" },
  ];

  const conditionOptions = [
    { value: "any", label: "Any", key: "Any" },
    { value: "new", label: "New", key: "new" },
    { value: "used", label: "Used", key: "used" },
    { value: "reclaim", label: "Reclaim", key: "reclaim" },
  ];

  const showingOptions = [
    { value: "active", label: "Active", key: "Active" },
    { value: "sold", label: "Sold", key: "Sold" },
  ];

  return (
    <div className="filter-bar">
      {Filter("showing", showingOptions, true)}
      {Filter("availability", availabilityOptions)}
      {Filter("material", materialOptions)}
      {Filter("condition", conditionOptions)}
    </div>
  );
}

export default FilterBar;
