import React, { useContext } from "react";
import "../UserRelation.css";
import ListingContext from "context/ListingContext";
import UserContext from "context/UserContext";
import ListingDates from "../ListingDates";

function ReserveOpener(props) {
  const { listingState, currentListing } = useContext(ListingContext);
  const { user } = useContext(UserContext);

  function buttonSwitcher() {
    if (currentListing.maker === user.userUuid) {
      return (
        <button
          type="button"
          disabled="true"
          className="button-primary wide reserve-btn bold disabled"
          onClick={() => {
            props.stepper();
          }}
        >
          {" "}
          This is your listing{" "}
        </button>
      );
    }
    if (listingState.isLoggedIn) {
      return (
        <button
          type="button"
          className="button-primary wide reserve-btn bold"
          onClick={() => {
            props.stepper();
          }}
        >
          {" "}
          Reserve{" "}
        </button>
      );
    }
    return (
      <button
        type="button"
        disabled="true"
        className="button-primary wide reserve-btn bold disabled"
        onClick={() => {
          props.stepper();
        }}
      >
        {" "}
        Log in to reserve{" "}
      </button>
    );
  }

  return (
    <div className="reserve-interior">
      <div>
        <div className="reserve-header rsv-subheader left">Reserve Now</div>
        <div className="rsv-helpfultext  left">
          Purchase now to reserve ownership. When the timeframe begins you will
          have access to pick up material.
        </div>
        <br />
        <br />
        <div className="reserve-header rsv-subheader left">
          Current Availability
        </div>
        <div>
          <ListingDates />
        </div>
        <br />
      </div>

      {buttonSwitcher()}
    </div>
  );
}

export default ReserveOpener;
