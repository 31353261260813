import { useNavigate } from "react-router-dom";

function ExpiredListing() {
  const navigate = useNavigate();

  return (
    <div className="reserve-container">
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div className="rsv-header left"> This listing has expired </div>{" "}
          <br />
          <div className="rsv-helpfultext">Sorry you missed out!</div>
          <div className="rsv-helpfultext">
            {" "}
            Fortunately, the marketplace is full of other cool stuff!
          </div>
        </div>
        <button
          className="button-terciary"
          type="button"
          onClick={() => {
            navigate("/");
          }}
        >
          {" "}
          Browse Jackrabbit Marketplace{" "}
        </button>
      </div>
    </div>
  );
}

export default ExpiredListing;
