import React, { createContext, useState } from "react";
import Userfront from "@userfront/react";
import { dateToString } from "../functions/functions";

const ListingContext = createContext();
export default ListingContext;

export function ListingProvider(props) {
  const [currentListing, setListing] = useState({});
  const [UserRelation, setUserRelation] = useState({
    isLoggedIn: false,
    isReserved: false,
    isOwner: false,
  });

  const setListingRated = () => {
    setListing((listing) => ({
      ...listing,
      is_rated: true,
    }));
  };

  const setReservationCompleted = () => {
    setListing((listing) => {
      if (listing.reservations && listing.reservations.length > 0) {
        const updatedReservations = [...listing.reservations];
        updatedReservations[0] = {
          ...updatedReservations[0],
          is_completed: true,
        };
        return {
          ...listing,
          reservations: updatedReservations,
        };
      }
      return listing;
    });
  };

  function checkReserved(listing) {
    const storedUserReservationList = JSON.parse(
      localStorage.getItem("userReservationList")
    );
    // eslint-disable-next-line
    for (let i = 0; i < storedUserReservationList.length; i++) {
      const reservation = storedUserReservationList[i];
      if (
        reservation.listing_id.listing_id === listing.listing_id ||
        reservation.listing_id === listing.listing_id
      ) {
        return true;
      }
    }
    return false;
  }

  const updateUserRelation = (listing) => {
    const isLoggedIn = Userfront.user.userUuid !== undefined;
    const isOwner = isLoggedIn && listing.maker === Userfront.user.userUuid;
    const isReserved = isLoggedIn && checkReserved(listing);

    setUserRelation({
      isLoggedIn,
      isReserved,
      isOwner,
    });
  };

  const updateListingState = (listing) => {
    const pickupStart = dateToString(listing.pickup_date_start);
    const pickupEnd = dateToString(listing.pickup_date_end);

    const updatedListing = {
      ...listing,
      dates: [pickupStart, pickupEnd],
    };

    setListing(updatedListing);
    updateUserRelation(updatedListing);
  };

  return (
    /* eslint-disable */
    <ListingContext.Provider
      value={{
        currentListing,
        listingState: UserRelation,
        updateListingState,
        setListingRated,
        setReservationCompleted,
      }}
    >
      {props.children}
    </ListingContext.Provider>
  );
}
