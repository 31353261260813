import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import Compressor from "compressorjs";
import "./ImageSelector.css";

function ImageSelector(props) {
  const chosenImages = props.answers;
  const [selectedImages, setSelectedImages] = useState(chosenImages || []);
  const [errorMessage, setErrorMessage] = useState();
  const MAX_IMAGE_QTY = 6;
  const editAnswers = props.editAnswers;
  const errorString = "Listings can only have up to 6 photos";
  const containerRef = useRef(null);

  useEffect(() => {
    editAnswers("listing_images", selectedImages);
  }, [selectedImages]);

  // Acts as a timer to display the errorMessage for only 5 seconds
  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage();
      }, 5000); // 5 seconds

      return () => clearTimeout(timer);
    }
    return undefined; // Satisfy ESLint
  }, [errorMessage]);

  function scrollTo() {
    if (containerRef.current) {
      const { top } = containerRef.current.getBoundingClientRect();
      const startingY = window.pageYOffset;
      const elementY = startingY + top;
      const targetY =
        document.documentElement.scrollHeight - window.innerHeight;
      const diff = elementY - targetY;

      const duration = 1000;
      let start;

      window.requestAnimationFrame(function step(timestamp) {
        if (!start) start = timestamp;
        const time = timestamp - start;
        const percent = Math.min(time / duration, 1);

        window.scrollTo(0, startingY + diff * percent);

        if (time < duration) {
          window.requestAnimationFrame(step);
        }
      });
    }
  }

  const compressImage = (image) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      new Compressor(image, {
        quality: 0.6,
        success: (compressedResult) => {
          resolve(compressedResult);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  };

  async function addImages(images) {
    const imagesToAdd = [];
    const MAX_SIZE = 4 * 1024 * 1024; // 4MB

    if (images.length + selectedImages.length > MAX_IMAGE_QTY)
      setErrorMessage(errorString);
    else setErrorMessage();

    for (
      let i = 0;
      i < Math.min(images.length, MAX_IMAGE_QTY - selectedImages.length); // cant limit image selection amount have to cap it after upload
      i++ // eslint-disable-line
    ) {
      imagesToAdd.push(images[i]);
    }

    const processedImages = await Promise.all(
      imagesToAdd.map(async (file) => {
        let processedFile = file;
        if (processedFile.size > MAX_SIZE) {
          processedFile = await compressImage(processedFile);
        }
        return {
          id: uuidv4(),
          file: processedFile || file,
          previewUrl: URL.createObjectURL(file),
        };
      })
    );
    setSelectedImages((prevImages) => [...prevImages, ...processedImages]);
    scrollTo();
  }

  const handleImageChange = (event) => {
    const images = event.target.files;
    addImages(images);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const images = event.dataTransfer.files;
    addImages(images);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleRemoveImage = (id) => {
    setSelectedImages((prevImages) =>
      prevImages.filter((image) => image.id !== id)
    );
  };

  function imagePreview(image) {
    return (
      <div className="image-box" key={image.id}>
        <img
          className="image-preview"
          key={`${image.id} image`}
          src={image.previewUrl}
          alt=""
        />
        <button
          className="button-terciary remove-button"
          type="button"
          key={`${image.id} button`}
          onClick={() => handleRemoveImage(image.id)}
        >
          X
        </button>
      </div>
    );
  }

  function uploaderText() {
    if (errorMessage) {
      return (
        <div
          style={{ textAlign: "center", fontSize: "16px", color: "#cc0000" }}
        >
          {errorMessage}
        </div>
      );
    }
    if (selectedImages.length === MAX_IMAGE_QTY)
      return (
        <div
          style={{ textAlign: "center", fontSize: "16px", color: "#5b5a5a" }}
        >
          {" "}
          6 photos selected{" "}
        </div>
      );
    if (selectedImages.length === 0)
      return (
        <div>
          <label className="image-upload-label" htmlFor="files">
            {" "}
            Drag and drop or click to upload photos{" "}
          </label>
        </div>
      );
    return (
      <label className="image-upload-label" htmlFor="files">
        {" "}
        {`${selectedImages.length} photo${
          selectedImages.length > 1 ? "s" : ""
        } selected - Drag or click to upload more`}{" "}
      </label>
    );
  }

  return (
    <div
      className="image-uploader text-center"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      ref={containerRef}
    >
      <div className="image-grid">
        {chosenImages.map((image) => (
          <div key={`${image.id} parent`}>{imagePreview(image)}</div>
        ))}
      </div>
      {uploaderText()}
      <input
        id="files"
        type="file"
        style={{ visibility: "hidden" }}
        onChange={handleImageChange}
        multiple
        accept="image/*"
        max="6"
      />
    </div>
  );
}

export default ImageSelector;
