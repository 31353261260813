import { motion, AnimatePresence } from "framer-motion";
import { LoadingAnimation } from "Business/Animations";
import { useViewTransition } from "./ViewTransition";

const fadeToWhite = {
  start: { opacity: 0 },
  end: { opacity: 1, backgroundColor: "white" },
};

const ViewTransitionWrapper = ({ children }) => {
  const { animate, animationText } = useViewTransition();

  return (
    <AnimatePresence>
      {animate ? (
        <motion.div
          key="loader"
          initial="start"
          animate="end"
          exit="start"
          variants={fadeToWhite}
          transition={{ duration: 0 }}
          style={{ position: "fixed", inset: 0, zIndex: 9999 }}
        >
          <LoadingAnimation />
          <div className="transition-text">{animationText}</div>
        </motion.div>
      ) : (
        <motion.div
          key="content"
          initial="out"
          animate="in"
          exit="out"
          variants={{ out: { opacity: 0 }, in: { opacity: 1 } }}
          transition={{ duration: 0.5 }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ViewTransitionWrapper;
