import React, { useState, useContext, createContext, useMemo } from "react";

const ViewTransitionContext = createContext();

export const useViewTransition = () => useContext(ViewTransitionContext);

export const ViewTransitionProvider = ({ children }) => {
  const [animate, setAnimate] = useState(false);
  const [animationText, setAnimationText] = useState("");

  const setAnimation = (state) => {
    setAnimate(true);
    if (state === "Maker") setAnimationText("Loading taker view");
    else setAnimationText("Loading maker view");

    setTimeout(() => setAnimate(false), 1000);
  };

  const value = useMemo(
    () => ({ animate, animationText, setAnimation }),
    [animate]
  );

  return (
    <ViewTransitionContext.Provider value={value}>
      {children}
    </ViewTransitionContext.Provider>
  );
};
