import React, { useEffect, useState, useContext } from "react";
import ListingContext from "context/ListingContext";
import Userfront from "@userfront/react";
import {
  errorMessageAlert,
  freeReserve,
  successfulReserveAlert,
  paidReserve,
} from "functions/functions";
import ReserveOpener from "./ReserveOpener";
import ReserveConfirm from "./ReserveConfirm";
import ReserveError from "./ReserveError";

function ReservationFlow() {
  const { currentListing, updateListingState } = useContext(ListingContext);
  const [step, setStep] = useState(0);
  const [reserveQty, setReserveQty] = useState(0);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    if (currentListing.quantity === 1) setReserveQty(1);
  }, [currentListing]);

  const reservation = {
    quantity_requested: reserveQty,
    taker_id: Userfront.user.userUuid,
    is_approved: false,
    listing_id: currentListing.listing_id,
  };

  const stepper = () => {
    setStep(step + 1);
  };

  const stepBack = () => {
    setStep(step - 1);
  };

  const reset = () => {
    setStep(0);
    setReserveQty(0);
  };

  const back = () => {
    if (step - 1 === 0) reset();
    else stepBack();
  };

  const reserveError = (error) => {
    setStep(10);
    setErrMsg(error.message);
    // eslint-disable-next-line
    console.error(error);
  };

  async function submitReservation() {
    try {
      const checkoutURL = await paidReserve(currentListing, reservation);
      window.location.href = checkoutURL;
    } catch (error) {
      reserveError(error);
    }
  }

  const reserve = () => {
    stepper();
    submitReservation();
  };

  function addReservationLocally(newRes) {
    const userReservationList =
      JSON.parse(localStorage.getItem("userReservationList")) || [];
    userReservationList.push(newRes);
    localStorage.setItem(
      "userReservationList",
      JSON.stringify(userReservationList)
    );
    const newListing = currentListing;
    newListing.reservations = [newRes];
    updateListingState(newListing);
  }

  async function submitFreeReservation() {
    const newReservation = {
      taker_id: Userfront.user.userUuid,
      listing_id: currentListing.listing_id,
      quantity_requested: currentListing.quantity,
    };

    try {
      const newRes = await freeReserve(currentListing, newReservation);
      addReservationLocally(newRes);

      successfulReserveAlert();
    } catch (error) {
      errorMessageAlert("failed to reserve this listing for you");
    }
  }

  const reserveFree = () => {
    submitFreeReservation();
  };

  function backButton() {
    return (
      <button type="button" className="back small-btn" onClick={() => back()}>
        back
      </button>
    );
  }

  function renderSwitch() {
    switch (step) {
      case 10:
        return <ReserveError reset={reset} setStep={setStep} errMsg={errMsg} />;

      case 2:
        return (
          <div className="reserve-interior">
            <div className="rsv-header left"> Sending you to Stripe </div>{" "}
          </div>
        );
      case 1:
        return (
          <ReserveConfirm
            stepper={stepper}
            reserve={reserve}
            reserveFree={reserveFree}
            backButton={backButton()}
          />
        );
      default:
        return <ReserveOpener stepper={stepper} />;
    }
  }

  return <div className="reserve-container">{renderSwitch()}</div>;
}

export default ReservationFlow;
