import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  getUser,
  loadMakerListings,
  loadMakerReviews,
  loadTakerReviews,
} from "functions/functions";
import RouteContext from "context/RouteContext";
import AccountHeader from "pages/Account/AccountHeader/AccountHeader";
import ProfileListings from "pages/UserProfile/ProfileBody/ProfileListings";
import ProfileReviews from "pages/UserProfile/ProfileBody/ProfileReviews";
import BreadCrumb from "components/Breadcrumb/BreadCrumb";
import ProfileBodySelector from "pages/UserProfile/ProfileBody/ProfileBodySelector";

function UserProfile() {
  const { updateConditionalText } = useContext(RouteContext);

  const [user, setUser] = useState({});
  const [loadedUser, setLoadedUser] = useState(false);
  const { id } = useParams();
  const [userListings, setUserListings] = useState({});
  const [loadedListings, setLoadedListings] = useState(false);
  const [takerReviews, setTakerReviews] = useState({});
  const [makerReviews, setMakerReviews] = useState({});
  const [loadedMakerReviews, setMakerLoaded] = useState(false);
  const [loadedTakerReviews, setTakerLoaded] = useState(false);
  const [loaded, setLoaded] = useState();
  const [fail, setFail] = useState(false);
  const [activeView, setView] = useState(0);
  // Determines whether the profile is to show maker statistics, or taker statistics
  // 0 for maker, 1 for taker
  // Hacky way to switch rating and reviews between the users maker-recieved and taker-recieved
  const [reviewState, setReviewState] = useState(1);
  const view = [
    <div key="reviews" className="container width-100">
      <ProfileReviews
        reviews={[makerReviews, takerReviews]}
        loaded={loaded}
        state={reviewState}
      />
    </div>,
    <ProfileListings
      key="Listings"
      userListings={userListings}
      loaded={loadedListings}
    />,
  ];

  // Get User
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await getUser(id);
        const state = userData.data.isMaker === true ? 0 : 1;
        setUser(userData);
        updateConditionalText(`${userData.name}'s profile`);
        setReviewState(state);
        setLoadedUser(true);
      } catch (error) {
        setLoadedUser(true);
        setFail(true);
      }
    };
    fetchUser();
  }, []);

  // Get Listings
  useEffect(() => {
    const fetchListings = async () => {
      try {
        const listings = await loadMakerListings(user.userUuid);
        setUserListings(listings);
        setLoadedListings(true);
      } catch (error) {
        setFail(true);
      }
    };
    if (user.userUuid && user.data.isMaker) fetchListings();
  }, [user]);

  // Get Reviews
  useEffect(() => {
    const fetchMakerReviews = async () => {
      try {
        const newMakerReviews = await loadMakerReviews(user.userUuid);
        setMakerReviews(newMakerReviews);
        setMakerLoaded(true);
      } catch (error) {
        setFail(true);
      }
    };
    const fetchTakerReviews = async () => {
      try {
        const newTakerReviews = await loadTakerReviews(user.userUuid);
        setTakerReviews(newTakerReviews);
        setTakerLoaded(true);
      } catch (error) {
        setFail(true);
      }
    };
    if (user.userUuid && user.data.isMaker) fetchMakerReviews();
    if (user.userUuid) fetchTakerReviews();
  }, [user]);

  useEffect(() => {
    if (user.data && user.data.isMaker)
      setLoaded(loadedMakerReviews && loadedTakerReviews);
    else setLoaded(loadedTakerReviews);
  }, [loadedMakerReviews, loadedTakerReviews]);

  const changeState = () => {
    if (reviewState) setReviewState(0);
    else setReviewState(1);
  };

  function renderSwitch() {
    if (loadedUser) {
      if (fail) {
        return (
          <div className="rsv-header item-header left">Failed to load user</div>
        );
      }
      return (
        <div className="user-bar-container">
          <AccountHeader
            user={user}
            state={reviewState}
            changeState={changeState}
          />
          <div className="breadcrumb-container-user-page">
            <BreadCrumb />
          </div>
          <ProfileBodySelector
            reviewCount={
              reviewState === 0
                ? user.data.maker_review_count
                : user.data.taker_review_count
            }
            listingCount={[userListings.length]}
            showTaker={reviewState}
            setView={setView}
          />
          {view[activeView]}
        </div>
      );
    }
    return "";
  }

  return (
    <div className="page-container column">
      <div className="faux-header" />
      {renderSwitch()}
    </div>
  );
}

export default UserProfile;
