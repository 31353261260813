import { useMessage } from "context/MessageContext";
import { errorMessageAlert } from "functions/functions";
import { useState } from "react";

function MessageSender({ updateChatOnNewMessage }) {
  const { trySendMessage } = useMessage();
  const [input, setInput] = useState("");
  const verifyInput = async () => {
    if (input.trim()) {
      try {
        await trySendMessage(input, updateChatOnNewMessage || null);
        setInput("");
      } catch (error) {
        errorMessageAlert("Failed to send your message");
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      verifyInput();
    }
  };

  const sendSvg = () => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22 2L11 13"
          stroke="#ffffff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 2L15 22L11 13L2 9L22 2Z"
          stroke="#ffffff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  return (
    <div className="message-sender">
      <textarea
        className="message-sender-input"
        placeholder="Type your message"
        value={input}
        onKeyDown={handleKeyPress}
        onChange={(event) => setInput(event.target.value)}
      />
      <button
        type="button"
        className="button-primary small-btn"
        onClick={verifyInput}
      >
        {sendSvg()}
      </button>
    </div>
  );
}

export default MessageSender;
