import React, { useState, useContext } from "react";
import RouteContext from "context/RouteContext";
import { useNavigate } from "react-router-dom";
import "./FormSteps.css";
import ListingAutofill from "./ListingAutofill";

const Step11 = ({
  answers,
  errors,
  editAnswers,
  setErrors,
  setStep,
  isValid,
  autoFilled,
  autofillAnswers,
  clearAutofill,
}) => {
  const { urls, popUrl } = useContext(RouteContext);

  const navigate = useNavigate();
  const materialOptions = [
    { value: "general", label: "General", key: "general" },
    { value: "concrete", label: "Concrete", key: "concrete" },
    { value: "electrical", label: "Electrical", key: "electrical" },
    { value: "finishes", label: "Finishes", key: "finishes" },
    { value: "masonry", label: "Masonry", key: "masonry" },
    { value: "mechanical", label: "Mechanical", key: "mechanical" },
    { value: "metals", label: "Metals", key: "metals" },
    { value: "plastics", label: "Plastics", key: "plastics" },
    { value: "tools", label: "Tools", key: "tools" },
    { value: "woods", label: "Woods", key: "woods" },
    { value: "other", label: "Other", key: "other" },
  ];

  const conditionOptions = [
    { value: "new", label: "New", key: "new" },
    { value: "used", label: "Used", key: "used" },
    { value: "reclaim", label: "Reclaim", key: "reclaim" },
  ];

  const [popupOpen, setPopupOpen] = useState(false);

  const clickNext = () => {
    if (isValid(answers, setErrors)) {
      setStep((prev) => prev + 1);
    }
  };

  /* [ ] TODO 2: pull a list of options from the **backend**
              so we don't have to manually maintain a list on the frontend */
  return (
    <div className="create-listing-container sans" id="step11">
      <div className="reg-label">Tell us about your listing</div>
      {!autoFilled ? (
        <button
          type="button"
          className="back small-btn left"
          onClick={() => setPopupOpen(true)}
        >
          {" "}
          or, autofill from a previous listing
        </button>
      ) : (
        <div className="inline">
          <button
            type="button"
            className="back small-btn left green"
            onClick={() => setPopupOpen(true)}
          >
            {" "}
            Listing successfully autofilled!
          </button>
          <button
            type="button"
            className="back small-btn left"
            onClick={() => clearAutofill()}
          >
            {" "}
            Clear autofill
          </button>
        </div>
      )}
      <ListingAutofill
        open={popupOpen}
        close={() => setPopupOpen(false)}
        autofillAnswers={autofillAnswers}
      />
      <div className="reg-label-2" htmlFor="category">
        What category does it fall under?
      </div>
      <select
        className="reg-select dropdown-select"
        id="category"
        onChange={(e) => {
          editAnswers("material", e.target.value);
        }}
        value={answers.material || ""}
      >
        <option value="" disabled hidden key="category">
          -- Select a Category --
        </option>
        {materialOptions.map((option) => (
          <option
            key={option.value}
            value={option.value}
            disabled={option.value === "empty"}
          >
            {option.label}
          </option>
        ))}
      </select>
      <div className="error-message">{errors.material}</div>
      <div className="reg-label-2" htmlFor="condition">
        What condition is it in?
      </div>
      <select
        className="reg-select dropdown-select"
        id="condition"
        onChange={(e) => {
          editAnswers("condition", e.target.value);
        }}
        value={answers.condition || ""}
      >
        <option value="" disabled hidden key="condition">
          -- Select a Condition --
        </option>
        {conditionOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="error-message">{errors.condition}</div>

      <div className="button-row">
        <button
          className="button-terciary reserve-confirm-btn left-side"
          type="button"
          onClick={() => {
            navigate(`${urls.previous === null ? "/" : urls.previous}`);
            popUrl();
            popUrl();
          }}
        >
          Cancel
        </button>
        <button
          className="button-primary reserve-confirm-btn right-side"
          onClick={clickNext}
          type="button"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default Step11;
