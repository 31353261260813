import { useEffect, useState, useContext } from "react";
import Userfront from "@userfront/react";
import {
  errorMessageAlert,
  getMakerChats,
  getTakerChats,
} from "functions/functions";
import { LoadingAnimation } from "Business/Animations";
import UserContext from "context/UserContext";
import OpenChat from "./OpenChat";
import "./Chats.css";
import ChatGrouper from "./ChatGrouper";
import MessagesTopBar from "./MessagesTopbar";

function Chats() {
  const [chats, setChats] = useState(null);
  const { user } = useContext(UserContext);
  const [active, setActive] = useState(false);
  const [filteredChats, setFiltered] = useState([]);

  useEffect(() => {
    if (chats) setFiltered(chats.filter((chat) => chat.completed === active));
  }, [chats, active]);

  const updateChatOnNewMessage = (updatedChat) => {
    setChats((prevChats) =>
      prevChats.map((chat) => (chat.id === updatedChat.id ? updatedChat : chat))
    );
  };

  useEffect(() => {
    const fetchMessages = async () => {
      let response;
      try {
        if (user?.data?.state === "Maker")
          response = await getMakerChats(Userfront.user.userUuid);
        else response = await getTakerChats(Userfront.user.userUuid);
        setChats(response);
      } catch (error) {
        errorMessageAlert("failed to load your messages");
      }
    };
    fetchMessages();
  }, [user]);

  return (
    <>
      {!chats && <LoadingAnimation />}
      <>
        <MessagesTopBar active={active} setActive={setActive} />
        {chats && (
          <div className="chats">
            {filteredChats.length > 0 ? (
              <ChatGrouper chats={filteredChats} />
            ) : (
              <div> No {active ? "archived" : "current"} messages </div>
            )}
            <OpenChat updateChatOnNewMessage={updateChatOnNewMessage} />
          </div>
        )}
      </>
    </>
  );
}

export default Chats;
