import React from "react";
import ImageDisplayer from "./ListingDetails/ImageDisplayer";
import ProductDetails from "./ListingDetails/ProductDetails";
import ListingHeader from "./ListingHeader";
import UserDetails from "./ListingDetails/UserDetails";
import LocationDetails from "./ListingDetails/LocationDetails";
import DateChangerWrapper from "./UserRelation/DateChangerWrapper";

function MakerView({ active }) {
  return (
    <div className="lv-wrapper">
      <ListingHeader />
      <div className="lv-container">
        <div className="lv-left">
          <ImageDisplayer />
          <ProductDetails active={active} />
        </div>
        <div className="lv-right">
          <DateChangerWrapper />
          <UserDetails maker />
          <LocationDetails />
        </div>
      </div>
    </div>
  );
}
export default MakerView;
