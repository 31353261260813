import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./PasswordChanger.css";
import Userfront from "@userfront/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import RouteContext from "../../../context/RouteContext";

function PasswordChanger() {
  const navigate = useNavigate();
  const [isActive, setActive] = useState(false);
  const [currPass, setCurPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showCurPassword, setShowCurPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { urls, popUrl } = useContext(RouteContext);

  function handleKeyDown(event) {
    /* prevent users from using a space character in the password */
    const isSpace = event.keyCode === 32;
    if (isSpace) {
      event.preventDefault();
    }
  }

  function clickHandler() {
    /* checks if password passes all requirements */
    const passwordError = document.getElementById("password-error");

    if (!currPass || !newPass || !confirmPass) {
      passwordError.innerText = "Please fill out all password fields.\n";
      return;
    }

    if (confirmPass !== newPass) {
      passwordError.innerText =
        "Passwords do not match. Please re-enter your password.\n";
      return;
    }

    const errors = [];

    if (newPass.length < 8) {
      errors.push("Password must be at least 8 characters long.\n");
    }

    if (!/\d/.test(newPass)) {
      errors.push("Password must contain at least one number.\n");
    }

    if (!/[!@#$%^&+=]/.test(newPass)) {
      errors.push("Password must contain at least one special character.\n");
    }

    if (errors.length > 0) {
      passwordError.innerHTML = "";
      errors.forEach((error) => {
        const errorMessage = document.createElement("span");
        errorMessage.innerText = error;
        passwordError.appendChild(errorMessage);
      });
      return;
    }

    Userfront.updatePassword({
      /* send a request to Userfront to update the password. If succcessful, display a success message */
      password: newPass,
      existingPassword: currPass,
    }).then(() => {
      setSuccessMessage("Password updated successfully!");
    });
  }

  function renderSwitch() {
    /* display different content based on the state of the isActive variable */
    switch (isActive) {
      case true:
        return (
          <div className="password-container">
            <div className="input-container">
              <input
                required
                className="input"
                type={showCurPassword ? "text" : "password"}
                placeholder="Your Current Password"
                onKeyDown={(event) => handleKeyDown(event)}
                onChange={(e) => setCurPass(e.target.value)}
                value={currPass}
                id="curr-pass"
              />
              <FontAwesomeIcon
                icon={showCurPassword ? faEyeSlash : faEye}
                className="eye-icon"
                onClick={() => setShowCurPassword(!showCurPassword)}
              />
              <input
                required
                className="input"
                type={showNewPassword ? "text" : "password"}
                placeholder="New Password"
                onKeyDown={(event) => handleKeyDown(event)}
                onChange={(e) => setNewPass(e.target.value)}
                value={newPass}
                id="new-pass"
              />
              <FontAwesomeIcon
                icon={showNewPassword ? faEyeSlash : faEye}
                className="eye-icon"
                onClick={() => setShowNewPassword(!showNewPassword)}
              />
              <input
                required
                className="input"
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm New Password"
                onKeyDown={(event) => handleKeyDown(event)}
                onChange={(e) => setConfirmPass(e.target.value)}
                value={confirmPass}
                id="confirm-pass"
              />
              <div id="password-error" />
              <FontAwesomeIcon
                icon={showConfirmPassword ? faEyeSlash : faEye}
                className="eye-icon3"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            </div>
            {successMessage ? (
              <p className="success-message">{successMessage}</p>
            ) : (
              <div className="button-container">
                <button
                  type="button"
                  className="button-primary"
                  onClick={() => clickHandler()}
                >
                  Update password
                </button>
                <button
                  type="button"
                  className="cancel-button"
                  onClick={() => {
                    setActive(false);
                    setSuccessMessage("");
                    setCurPass("");
                    setNewPass("");
                    setConfirmPass("");
                  }}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        );
      default:
        return (
          <div>
            <button
              type="button"
              className="button-primary big-btn"
              onClick={() => setActive(true)}
            >
              {" "}
              Update{" "}
            </button>
          </div>
        );
    }
  }

  return (
    <div className="pw-container">
      <button
        type="button"
        className="back-button"
        onClick={() => {
          navigate(`${urls.previous === null ? "/" : urls.previous}`);
          popUrl();
          popUrl();
        }}
      >
        Back
      </button>

      <h1>Password Settings</h1>
      <div> {renderSwitch()}</div>
    </div>
  );
}
export default PasswordChanger;
