import { useEffect, useState, useContext } from "react";
import axios from "axios";
import {
  errorMessageAlert,
  loadListingsByMonth,
  loadReservationsByMonth,
} from "functions/functions";
import { LayoutGroup, motion } from "framer-motion";
import UserContext from "context/UserContext";
import OpenChat from "pages/Messages/OpenChat";
import MessagesOpen from "../ListingView/MessagesOpen";
import CalendarBody from "./CalendarBody";
import CalendarHeader from "./CalendarHeader";
import "./Calendar.css";

import CalendarDayOpen from "./CalendarDayOpen";

function Calendar() {
  const [date, setDate] = useState(new Date());
  const [listings, setListings] = useState(null);
  const [reservations, setReservations] = useState(null);
  const [showListings, setShowListings] = useState(true);
  const [showReservations, setShowReservations] = useState(true);
  const [openDay, setOpenDay] = useState(false);
  const [chats, setChats] = useState(null);
  const { user } = useContext(UserContext);

  const updateListing = (updatedListing) => {
    setOpenDay(false);
    setListings(
      listings.map((listing) =>
        listing.listing_id === updatedListing.listing_id
          ? updatedListing
          : listing
      )
    );
  };

  const changeShowRes = () => {
    setShowReservations(!showReservations);
  };
  const resObj = {
    active: showReservations,
    change: changeShowRes,
    text: reservations
      ? reservations.length +
        (reservations.length === 1 ? " reservation" : " reservations")
      : "Loading",
  };

  const changeShowListings = () => {
    setShowListings(!showListings);
  };
  const listingObj = {
    active: showListings,
    change: changeShowListings,
    text: listings
      ? listings.length + (listings.length === 1 ? " listing" : " listings")
      : "Loading",
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const fetchListings = async () => {
      setListings(null);
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      try {
        const fetchedListings = await loadListingsByMonth(
          month,
          year,
          cancelToken.token
        );
        setListings(fetchedListings);
      } catch (error) {
        if (!axios.isCancel(error)) {
          setListings([]);
          if (error.response && error.response.status !== 404) {
            errorMessageAlert("failed to load your listings");
          }
        }
      }
    };
    const fetchReservations = async () => {
      setReservations(null);
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      try {
        const fetchedReservations = await loadReservationsByMonth(
          month,
          year,
          cancelToken.token
        );
        setReservations(fetchedReservations);
      } catch (error) {
        if (!axios.isCancel(error)) {
          setReservations([]);
          if (error.response && error.response.status !== 404) {
            errorMessageAlert("failed to load your reservations");
          }
        }
      }
    };

    if (user && user?.data?.state === "Maker") fetchListings();
    else fetchReservations();

    return () => {
      cancelToken.cancel();
    };
  }, [date, user]);

  return (
    <>
      <div className="calendar">
        <LayoutGroup>
          <motion.div layout className="calendar-container">
            <CalendarHeader
              date={date}
              setDate={setDate}
              resObj={resObj}
              listingObj={listingObj}
            />
            <CalendarBody
              date={date}
              listings={listings}
              showListings={showListings}
              showReservations={showReservations}
              reservations={reservations}
              setOpenDay={setOpenDay}
            />
          </motion.div>
          <CalendarDayOpen
            openDay={openDay}
            setOpenDay={setOpenDay}
            setChats={setChats}
            updateListing={updateListing}
          />
        </LayoutGroup>
      </div>
      <MessagesOpen open={chats} close={() => setChats(null)} chats={chats} />
      <OpenChat />
    </>
  );
}

export default Calendar;
