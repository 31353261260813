import React, { useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./AccountDropDown.css";
import { logoutAlert } from "functions/functions";
import UserContext from "context/UserContext";
import options from "Business/options";
import { dashboardSvg, logoutSvg, hamburgerSvg, closeSvg } from "Business/svg";
import DropDownOption from "./DropdownOption";
import useOutsideClick from "../../functions/useOutsideClick";
import ViewSwitcher from "./ViewSwitcher";
import MakerButton from "./MakerButton";

function AccountDropDown() {
  const { user, loadedUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const ref = useRef();

  useOutsideClick(ref, () => {
    setOpen(false);
  });

  function clickHandler(link) {
    setOpen(false);
    navigate(link);
  }

  return (
    <div className="top-right">
      <div
        className={`dropdown-container ${
          user?.data?.state === "Maker" ? "dc-dark" : ""
        }`}
        ref={ref}
      >
        <button
          type="button"
          className={`hamburger-button ${isOpen ? "hamburger-open" : ""} ${
            user?.data?.state === "Maker" ? "dco-dark" : ""
          }`}
          onClick={() => setOpen(!isOpen)}
          aria-label="menu"
        >
          <div className="row">
            {isOpen ? closeSvg() : hamburgerSvg()}

            {loadedUser && (
              <img className="account-picture" src={user.image} alt="." />
            )}
          </div>
        </button>

        <ul
          className={`dropdown-items ${isOpen ? "dropdown-open" : ""} ${
            user?.data?.state === "Maker" ? "dco-dark" : ""
          }`}
        >
          <DropDownOption
            key="Dashboard"
            option={{ name: "Dashboard", svg: dashboardSvg() }}
            clickHandler={() => clickHandler("/dashboard")}
          />

          <hr className="dropdown-line" />

          {options()
            .slice(0, 3)
            .map((option) => (
              <DropDownOption
                key={option.name}
                option={option}
                clickHandler={() => clickHandler(option.url)}
              />
            ))}

          <hr className="dropdown-line" />
          {user?.data?.isMaker ? (
            <ViewSwitcher close={() => setOpen(false)} />
          ) : (
            <MakerButton />
          )}
          <DropDownOption
            key="logout"
            option={{ name: "Log Out", svg: logoutSvg() }}
            clickHandler={() => logoutAlert()}
          />
        </ul>
      </div>
    </div>
  );
}

export default AccountDropDown;
