function AnotherUserReserved() {
  return (
    <div className="reserve-container">
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {" "}
        <div>
          {" "}
          <div className="rsv-header left"> Another user is reserved </div>{" "}
          <div className="rsv-helpfultext">
            {" "}
            We are working on implementing a stand-by system for reservations,
            for the event that the original Taker cancels. Please bear with us
            as we expand our functionality.{" "}
          </div>{" "}
        </div>{" "}
        <div>
          {" "}
          {/* To do: Implement waitlist */}
          <button
            type="button"
            className="button-terciary wide reserve-btn bold"
            disabled
          >
            {" "}
            <s> Join standby list </s>{" "}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AnotherUserReserved;
