import React, { useEffect, useContext } from "react";
import "./App.css";
import Userfront from "@userfront/react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useMessage } from "context/MessageContext";
import Footer from "components/Footer/Footer";
import { loadReservations } from "./functions/functions";
import Navbar from "./components/Topbar/Navbar";
import ScrollToTop from "./functions/scrollToTop";
import RouteContext from "./context/RouteContext";

export function RequireAuth({ children }) {
  const location = useLocation();
  if (!Userfront.tokens.accessToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export function App() {
  const { pushUrl } = useContext(RouteContext);
  const location = useLocation();
  const { setCurrentChat } = useMessage();

  useEffect(() => {
    loadReservations();
  }, []);

  useEffect(() => {
    pushUrl(location.pathname);
    setCurrentChat(null);
  }, [location]);

  return (
    <>
      <div className="App">
        <ScrollToTop />
        <Navbar />
        <Outlet />
      </div>
      <Footer />
    </>
  );
}

export default App;
