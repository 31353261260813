import MakerReserve from "Business/Email/MakerReserve";

const { default: NewMessage } = require("Business/Email/NewMessage");

function EmailTest() {
  const mockMessageObj = {
    sender_first_name: "Sender",
    sender_url:
      "https://res.cloudinary.com/component/image/upload/avatars/avatar-plain-6.png",
    recipient_first_name: "Recipient",
    message_data: {
      content: "This is the message content",
    },
  };

  const mockMakerReserveObj = {
    maker: {
      first_name: "Maker",
    },
    listing: {
      name: "Listing name",
      listing_id: 250,
    },
    taker: {
      name: "Taker",
      image:
        "https://res.cloudinary.com/component/image/upload/avatars/avatar-plain-6.png",
    },
  };
  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <NewMessage obj={mockMessageObj} />
      <MakerReserve obj={mockMakerReserveObj} />
    </>
  );
}

export default EmailTest;
