import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  confirmPickupAlert,
  errorMessageAlert,
  completeReservation,
  takerSubmitRating,
  thankYou,
} from "functions/functions";
import RatingPopup from "components/Rating/RatingPopup";
import ListingContext from "context/ListingContext";

function UserIsReserved() {
  const { currentListing, setReservationCompleted } =
    useContext(ListingContext);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const now = new Date();
  const pickupStartDate = new Date(currentListing.pickup_date_start);

  const submitRating = async (rating) => {
    try {
      await takerSubmitRating(
        rating,
        currentListing.maker,
        currentListing.reservations[0]
      );
      setShowPopup(false);
      setReservationCompleted();
      thankYou();
    } catch (error) {
      errorMessageAlert("failed to post your review");
    }
  };

  const confirmPickup = async (resToConfirm) => {
    const response = await confirmPickupAlert(currentListing.name);
    if (response.isConfirmed) {
      try {
        await completeReservation(resToConfirm.reservation_id);
        setShowPopup(true);
      } catch (error) {
        errorMessageAlert("failed to confirm your pickup");
      }
    }
  };

  if (pickupStartDate < now) {
    return (
      <>
        <div className="reserve-container">
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {" "}
            <div>
              {" "}
              <div className="rsv-header left">
                {" "}
                Your reservation is ready for pickup!{" "}
              </div>{" "}
              <br />
              <div className="rsv-helpfultext">
                {" "}
                Once you have picked up your reservation, confirm below to
                complete the transaction and to rate the Maker{" "}
              </div>{" "}
            </div>{" "}
            <div>
              {" "}
              <button
                type="button"
                className="button-primary wide reserve-btn bold"
                onClick={() => confirmPickup(currentListing.reservations[0])}
              >
                {" "}
                Confirm Pickup{" "}
              </button>
            </div>
          </div>
        </div>
        {showPopup && (
          <RatingPopup
            submitRating={(rating) => submitRating(rating)}
            reservation={currentListing.reservations[0]}
            open={showPopup}
            close={() => setShowPopup(false)}
            forMaker
          />
        )}
      </>
    );
  }

  return (
    <div className="reserve-container">
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {" "}
        <div>
          {" "}
          <div className="rsv-header left"> You are reserved </div> <br />
          <div className="rsv-helpfultext">
            {" "}
            Get ready to jump on it! The Maker will update the timeframe as the
            availability window approaches. Please keep yourself updated.{" "}
          </div>{" "}
        </div>{" "}
        <div>
          {" "}
          <button
            type="button"
            className="button-terciary wide reserve-btn bold"
            onClick={() => navigate("/your-reservations")}
          >
            {" "}
            View reservations{" "}
          </button>
        </div>
      </div>
    </div>
  );
}

export default UserIsReserved;
