export function locationSplit(input) {
  if (input.split(",")[1] !== undefined)
    return input.split(",")[1].slice(0, -2).trim();
  return "Unknown";
}

/* Move this to create listing when applicable */
export function capitalizer(listing) {
  return listing.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
    letter.toUpperCase()
  );
}

/* To get the date from datetime */
export function dateRegex(input) {
  return /([0-9]+(-[0-9]+)+)/.exec(input);
}
