import React from "react";
import library from "Business/Images/JackrabbitLibrary.png";

function Landing({ setVisited }) {
  return (
    <div className="page-container landing-container">
      <div className="landing-banner">
        <div className="landing-header">
          <h1 className="landing-title">
            {" "}
            Making Salvage Easier, <br /> one jobsite at a time.{" "}
          </h1>
          <div className="rsv-helpfultext text-bigger">
            {" "}
            With JackRabbit, nothing goes to waste.{" "}
          </div>
          <button
            className="button-primary big-btn"
            onClick={() => setVisited()}
            type="button"
            style={{ marginTop: "2rem", width: "80%", marginInline: "auto" }}
          >
            {" "}
            Explore our Marketplace{" "}
          </button>
        </div>

        <div className="landing-image-container">
          <img
            src={library}
            alt="Kennedy Library Renovations"
            className="landing-image"
          />
        </div>
      </div>
      <div className="landing-row testimonial-row">
        <div className="rsv-helpfultext landing-testimonial">
          {" "}
          <div className="testimonial testimonial-quote">
            &quot;JackRabbit demonstrated commitment to salvage and reuse at the
            Cal Poly Kennedy Library Transformation project. I would recommend
            them to anyone striving toward LEED certification with regard to C&D
            waste diversion.&quot;
          </div>
          <div className="testimonial">
            -Will C, LEED AP, Project Manager - Hensel Phelps{" "}
          </div>
        </div>
      </div>
      <div className="landing-row">
        <div className="landing-row-item">
          <h2 className="row-item-header">PLAN</h2>
          <div className="rsv-helpfultext row-item-text">
            We partner with you and build on your plan for waste diversion –
            Each site receives care in assessing salvageability of key materials
            and interpreting optimal workflow of removal. We know that the
            people on site make great things happen, so we gain understanding of
            how each project’s team allocates tasks and how to get the most out
            of each member’s capabilities and requirements, including trade
            partners.
          </div>
        </div>
        <div className="landing-row-item">
          <h2 className="row-item-header">EXECUTE</h2>
          <div className="rsv-helpfultext row-item-text">
            We execute with persistence and efficiency – Our platform is built
            with the contractor in mind and we know that builders are the hero
            in this journey. We are dedicated to diverting from the waste stream
            and we know that when and where the rubber meets the road, agility,
            focus and perseverance gets the job done as your project demands.
          </div>
        </div>
        <div className="landing-row-item">
          <h2 className="row-item-header">VALIDATE</h2>
          <div className="rsv-helpfultext row-item-text">
            We validate each diversion from the waste stream with assurance –
            Authentication of materials leaving your site through our services
            are carefully measured and monitored and a meticulous record is
            kept. Details are forthcoming, and documentation provided on our
            letterhead including description, volume and process for removing
            material, recipients, and eventual reuse plan so that you have peace
            of mind regarding any future inquiry about salvage operations.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
