function ReserveError(props) {
  return (
    <div className="reserve-interior">
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div className="rsv-header left"> Submission Error</div>
          <button
            type="button"
            className="back small-btn"
            onClick={() => props.reset()}
          >
            cancel
          </button>
        </div>
        <div className="rsv-helpfultext left">{props.errMsg} </div>
        <br />
        <div className="rsv-helpfultext left">Please try again</div>
      </div>
      <button
        type="button"
        className="button-primary wide reserve-cont-btn"
        onClick={() => props.setStep(2)}
      >
        Try again
      </button>
    </div>
  );
}

export default ReserveError;
