import React, { useEffect, useContext, useState } from "react";
import ListingsContext from "context/ListingsContext";
import { LoadingAnimation } from "Business/Animations";
import { useNavigate } from "react-router-dom";
import ListingPopulator from "./ListingPopulator";
import BigMap from "./BigMap";

function MapView() {
  const { shownListings } = useContext(ListingsContext);
  const [loaded, setLoaded] = useState(false);
  const [activeId, setActive] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (shownListings !== undefined) {
      setLoaded(true);
    }
  }, [shownListings]);

  const listingNavigator = (listing) => {
    navigate(`/listings/${listing.listing_id}`);
  };

  return loaded ? (
    <div className="map-view">
      <div className="map-view-left">
        <ListingPopulator
          allListings={shownListings}
          navigator={listingNavigator}
          activeId={activeId}
        />
      </div>
      <div className="map-view-right">
        <BigMap listings={shownListings} setActive={setActive} />
      </div>
    </div>
  ) : (
    <LoadingAnimation />
  );
}

export default MapView;
