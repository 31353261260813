import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Listings.css";
import ListingsContext from "context/ListingsContext";
import { LoadingAnimation } from "Business/Animations";
import UserContext from "context/UserContext";
import ListingPopulator from "./ListingPopulator";

function Listings() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { shownListings } = useContext(ListingsContext);
  const [loaded, setLoaded] = useState(false);

  function listingNavigator(listing) {
    navigate(`/listings/${listing.listing_id}`);
  }

  useEffect(() => {
    if (shownListings !== undefined) {
      setLoaded(true);
    }
  }, [shownListings]);

  function renderSwitch(input) {
    switch (loaded) {
      case true:
        return (
          <div className="listings listings-home">
            {" "}
            {ListingPopulator(input, listingNavigator)}{" "}
          </div>
        );
      default:
        return <LoadingAnimation />;
    }
  }

  function newListingButton() {
    return (
      <button
        type="button"
        onClick={() => {
          navigate("/create-listing");
        }}
        className="create-listing-btn"
      >
        <svg viewBox="0 0 24 24">
          <path
            d="M17,11H13V7a1,1,0,0,0-2,0v4H7a1,1,0,0,0,0,2h4v4a1,1,0,0,0,2,0V13h4a1,1,0,0,0,0-2Z"
            fill="#484848"
          />
        </svg>
        <div className="text">New Listing</div>
        <span className="plus-icon">+</span>
      </button>
    );
  }

  return (
    <div className="listings-parent">
      {renderSwitch(shownListings)}
      {user.data && user.data.isMaker && newListingButton()}
    </div>
  );
}

export default Listings;
