import React, { useContext } from "react";
import ListingContext from "context/ListingContext";
import UserContext from "context/UserContext";
import MakerViewParent from "./MakerViewParent";
import TakerView from "./TakerView";

function ViewDecider() {
  const { listingState } = useContext(ListingContext);
  const { user } = useContext(UserContext);
  const isMaker = listingState.isOwner;
  const viewToRender =
    isMaker && user.data.state === "Maker" ? (
      <MakerViewParent />
    ) : (
      <TakerView />
    );

  return viewToRender;
}

export default ViewDecider;
