import ChatPopulator from "./ChatPopulator";

function ChatGrouper({ chats }) {
  const groupedChats = chats.reduce((acc, obj) => {
    const key = obj.listing_name;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});

  return (
    <div>
      {Object.keys(groupedChats).map((group) => (
        <div key={group}>
          <div className="message-group">
            <div className="rsv-category">{group}</div>
            <ChatPopulator chats={groupedChats[group]} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default ChatGrouper;
