import { useNavigate } from "react-router-dom";

function NewListingButton() {
  const navigate = useNavigate();

  return (
    <button
      type="button"
      onClick={() => {
        navigate("/create-listing");
      }}
      className="create-listing-btn"
    >
      <svg viewBox="0 0 24 24">
        <path
          d="M17,11H13V7a1,1,0,0,0-2,0v4H7a1,1,0,0,0,0,2h4v4a1,1,0,0,0,2,0V13h4a1,1,0,0,0,0-2Z"
          fill="#484848"
        />
      </svg>
      <div className="text">New Listing</div>
      <span className="plus-icon">+</span>
    </button>
  );
}

export default NewListingButton;
