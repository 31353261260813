import React from "react";
import { useNavigate } from "react-router-dom";
import ListingPopulator from "pages/Marketplace/Listings/ListingPopulator";
import { LoadingAnimation } from "Business/Animations";

function ProfileListings(props) {
  const navigate = useNavigate();

  function listingNavigator(listing) {
    navigate(`/listings/${listing.listing_id}`, {
      state: { listing },
    });
  }

  function renderSwitch() {
    switch (props.loaded) {
      case true:
        return (
          <div className="listings listings-profile">
            {" "}
            {ListingPopulator(props.userListings, listingNavigator)}{" "}
          </div>
        );
      default:
        return <LoadingAnimation />;
    }
  }

  return <>{renderSwitch()}</>;
}

export default ProfileListings;
