import React, { useEffect, useState, useContext } from "react";
import { Modal } from "react-responsive-modal";
import { v4 as uuidv4 } from "uuid";
import {
  errorMessageAlert,
  uploadUserPicture,
  afterUserPictureChange,
} from "functions/functions";
import UserContext from "context/UserContext";
import { ButtonAnimation } from "Business/Animations";
import UserPictureSelector from "./UserPictureSelector";

const UserPicturePopup = (props) => {
  const { updateUser } = useContext(UserContext);
  const [newImage, setImage] = useState();
  const setShowPopup = props.setShowPopup;
  const showPopup = props.showPopup;
  const [tempUrl, setTempUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (newImage) setTempUrl(newImage.previewUrl);
  }, [newImage]);

  const addImage = (image) => {
    const imageObject = {
      id: uuidv4(),
      image,
      previewUrl: URL.createObjectURL(image),
    };
    setImage(imageObject);
  };

  const submitUpload = async () => {
    setLoading(true);
    try {
      uploadUserPicture(newImage)
        .then(() => setShowPopup(false))
        .then(() => updateUser())
        .then(() => afterUserPictureChange());
    } catch (error) {
      setLoading(false);
      errorMessageAlert("failed to change your profile picture.");
    }
  };

  return (
    <Modal
      open={showPopup}
      onClose={() => setShowPopup(false)}
      center
      blockScroll
      classNames={{
        modal: "profile-upload-modal",
      }}
    >
      <div className="reserve-header rsv-header floatleft ">
        Upload a new Profile Picture{" "}
      </div>
      <br />
      <UserPictureSelector src={tempUrl} addImage={addImage} />
      <br />
      {loading ? (
        <button
          type="button"
          className="button-primary wide reserve-btn"
          disabled
        >
          <ButtonAnimation />
        </button>
      ) : tempUrl ? (
        <button
          type="button"
          className="button-primary wide reserve-btn"
          onClick={() => submitUpload()}
        >
          {" "}
          Save{" "}
        </button>
      ) : (
        <button
          type="button"
          className="button-terciary wide reserve-btn"
          disabled
        >
          {" "}
          Select{" "}
        </button>
      )}
    </Modal>
  );
};

export default UserPicturePopup;
