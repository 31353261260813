import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "context/UserContext";
import Userfront from "@userfront/react";
import Search from "pages/Marketplace/Topbar/Search";
import UserFunctionality from "../UserFunctionality/UserFunctionality";
import Signup from "../UserFunctionality/Signup";
import Login from "../UserFunctionality/Login";
import "./Navbar.css";

function Navbar() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [buttonText, setButtonText] = useState("");
  const [signupOpen, setSignupOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [newUser, setNewUser] = useState(false);

  useEffect(() => {
    const currentURL = window.location.href;
    if (currentURL.endsWith("new-user") && !Userfront.tokens.accessToken) {
      setSignupOpen(true);
      setNewUser(true);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const newScreenWidth = window.innerWidth;
      setButtonText(newScreenWidth <= 575 ? "JSM" : "JackRabbit");
    };

    window.addEventListener("resize", handleResize);

    // Initial setting of the button text
    setButtonText(window.innerWidth < 575 ? "JSM" : "JackRabbit");
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  const navigateLanding = () => {
    localStorage.setItem("visited", "false");
    const currentURL = window.location.pathname;
    if (currentURL === "/") window.location.reload();
    else navigate("/");
  };

  function renderSwitch() {
    // checks to see if user is not logged in
    if (!Userfront.tokens.accessToken) {
      // logged out navbar
      return (
        <>
          <button
            type="button"
            className="home-button clickable"
            onClick={() => navigateLanding()}
          >
            {buttonText}
            <div className="txt-over-button tob-taker">Jump On It.</div>
          </button>
          <div className="navbar-items">
            <div className="login-buttons">
              <button
                type="button"
                className="button-terciary big-btn"
                onClick={() => setLoginOpen(true)}
              >
                {" "}
                Log In{" "}
              </button>
              <button
                type="button"
                className="button-primary big-btn"
                onClick={() => setSignupOpen(true)}
              >
                Sign Up
              </button>
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        {user?.data?.state === "Maker" ? (
          <button
            type="button"
            className="home-button clickable"
            onClick={() => navigate("/dashboard")}
          >
            {" "}
            {buttonText}
            <div className="txt-over-button">Maker</div>
          </button>
        ) : (
          <button
            type="button"
            className="home-button clickable"
            onClick={() => navigateLanding()}
          >
            {" "}
            {buttonText}
            <div className="txt-over-button tob-taker">Jump On It.</div>
          </button>
        )}
        {user?.data?.state !== "Maker" && <Search />}
        <UserFunctionality />
      </>
    );
  }

  function LoginSignupSwitch() {
    if (signupOpen) {
      setSignupOpen(false);
      setLoginOpen(true);
    } else {
      setLoginOpen(false);
      setSignupOpen(true);
    }
  }

  return (
    <>
      <div className="navbar flex-between">{renderSwitch()}</div>
      <Signup
        open={signupOpen}
        switcher={() => LoginSignupSwitch()}
        close={() => setSignupOpen(false)}
        newUser={newUser}
      />
      <Login
        open={loginOpen}
        switcher={() => LoginSignupSwitch()}
        close={() => setLoginOpen(false)}
      />
    </>
  );
}

export default Navbar;
