import AccountDropDown from "./AccountDropDown";

function UserFunctionality() {
  return (
    <div className="top-right">
      <AccountDropDown />
    </div>
  );
}

export default UserFunctionality;
