import { useEffect, useState, useContext } from "react";
import OpenChat from "pages/Messages/OpenChat";
import ListingContext from "context/ListingContext";
import MakerView from "./MakerView";
import ListingTopBar from "./ListingTopBar";
import MessagesOpen from "./MessagesOpen";
import { getListingChats } from "../../functions/functions";

function MakerViewParent() {
  const { currentListing } = useContext(ListingContext);
  const [active, setActive] = useState("home");
  const [chats, setChats] = useState(null);

  useEffect(() => {
    const fetchListingChats = async () => {
      try {
        const loadedChats = await getListingChats(currentListing.listing_id);
        setChats(loadedChats);
      } catch (error) {
        setChats(null);
      }
    };

    fetchListingChats();
  }, []);

  const updateChatOnNewMessage = (updatedChat) => {
    setChats((prevChats) =>
      prevChats.map((chat) => (chat.id === updatedChat.id ? updatedChat : chat))
    );
  };

  return (
    <>
      <ListingTopBar active={active} setActive={setActive} />
      <MakerView active={active} />
      <MessagesOpen
        open={active === "Messages"}
        close={() => setActive("Home")}
        chats={chats}
      />
      <OpenChat updateChatOnNewMessage={updateChatOnNewMessage} />
    </>
  );
}
export default MakerViewParent;
