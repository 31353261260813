import React, { useState, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import {
  errorMessageAlert,
  loadMakerListings,
  failedToLoadImageAlert,
} from "functions/functions";
import Userfront from "@userfront/react";
import { LoadingAnimation } from "Business/Animations";
import { v4 as uuidv4 } from "uuid";

function ListingAutofill(props) {
  const [listings, setListings] = useState(null);
  const userUuid = Userfront.user.userUuid;
  const NO_IMAGE_URL = process.env.REACT_APP_NO_IMAGE_URL;

  const fetchListings = async () => {
    try {
      const data = await loadMakerListings(userUuid);
      setListings(data);
    } catch (error) {
      errorMessageAlert(`failed to load your listings`);
    }
  };

  function getImage(listingImages) {
    if (!listingImages || listingImages === "[]") return NO_IMAGE_URL;
    return JSON.parse(listingImages)[0];
  }

  useEffect(() => {
    if (userUuid) fetchListings();
  }, []);

  async function getImageObjectFromUrl(imageUrl) {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const file = new File([blob], "downloaded_image.jpg", {
        type: blob.type,
      });
      return {
        id: uuidv4(),
        file,
        previewUrl: URL.createObjectURL(file),
      };
    } catch (error) {
      failedToLoadImageAlert();
      return null;
    }
  }

  async function handleClick(listing) {
    try {
      // Converts the listing images to the imageObjects format
      let listingImagesObjects = await Promise.all(
        JSON.parse(listing.listing_images).map(async (imageUrl) => {
          return getImageObjectFromUrl(imageUrl);
        })
      );

      listingImagesObjects = listingImagesObjects.filter(
        (imageObject) => imageObject !== null
      );

      const updatedFields = {
        title: listing.name,
        description: listing.description,
        material: listing.material,
        condition: listing.condition,
        quantity: listing.quantity,
        total_price: listing.unit_price.toString(),
        pickup_date_start: listing.pickup_date_start,
        pickup_date_end: listing.pickup_date_end,
        location: listing.location,
        pickup_instructions: listing.pickup_instructions,
        listing_images: listingImagesObjects,
      };

      props.autofillAnswers(updatedFields);
      props.close();
    } catch (error) {
      errorMessageAlert(`failed to autofill your listing`);
    }
  }

  function renderBody() {
    if (listings === null)
      return (
        <div className="loading-div">
          {" "}
          <LoadingAnimation />{" "}
        </div>
      );
    if (listings.length === 0)
      return (
        <div className="loading-div loading-div-short">
          {" "}
          <div className="modal-body">
            <div className="rsv-subheader no-margin center">
              {" "}
              You have no previous listings, start by making a new one!{" "}
            </div>
            <button
              className="button-primary"
              type="button"
              onClick={() => props.close()}
            >
              {" "}
              Close{" "}
            </button>{" "}
          </div>
        </div>
      );
    return (
      <div className="listing-columns">
        {" "}
        {listings.map((listing) => (
          <button
            key={listing.listing_id}
            type="button"
            className="autofill-button button-terciary"
            onClick={() => handleClick(listing)}
          >
            <img
              className="small-listing-image"
              src={getImage(listing.listing_images)}
              alt=""
            />
            <div className="autofill-button-text left">
              <div className="rsv-subheader no-margin">{listing.name}</div>
              <div className="rsv-helpfultext no-bold">{listing.location}</div>
            </div>
          </button>
        ))}
      </div>
    );
  }

  return (
    <Modal
      open={props.open}
      onClose={() => props.close()}
      center
      blockScroll
      classNames={{
        modal: "customModal customModalWide",
      }}
    >
      <div
        className="subheader rsv-header left"
        style={{ marginInline: "auto", marginTop: "1rem" }}
      >
        Select a listing to autofill
      </div>

      {renderBody()}
    </Modal>
  );
}

export default ListingAutofill;
