import React, { useContext } from "react";
import ListingContext from "context/ListingContext";

function ProductDetails() {
  const { currentListing } = useContext(ListingContext);

  return (
    <div className="info-container">
      <div className="desc">
        <div className="item-details">
          <div className="rsv-subheader left"> Details </div>
          <div className="rsv-helpfultext  left">
            {" "}
            Condition:{" "}
            <div className="rsv-subheader" style={{ display: "inline" }}>
              {" "}
              {currentListing.condition}{" "}
            </div>
          </div>
          <div className="rsv-helpfultext  left">
            {" "}
            Quantity:{" "}
            <div className="rsv-subheader" style={{ display: "inline" }}>
              {" "}
              {currentListing.quantity}{" "}
            </div>
          </div>
          <div className="rsv-helpfultext  left">
            {" "}
            Total Price:{" "}
            <div className="rsv-subheader" style={{ display: "inline" }}>
              {" "}
              ${currentListing.unit_price}{" "}
            </div>
          </div>
          <div className="rsv-helpfultext  left">
            {" "}
            Availability:{" "}
            <div className="rsv-subheader" style={{ display: "inline" }}>
              {" "}
              {currentListing.dates[0]} - {currentListing.dates[1]}{" "}
            </div>
          </div>
        </div>
        <div className="product-details">
          {currentListing.description !== null &&
          currentListing.description !== "" ? (
            <div className="item-desc left">
              <br />
              <div className="rsv-subheader"> Description </div>

              <div className="rsv-helpfultext  left">
                {" "}
                {currentListing.description}{" "}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
