import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import RatingPopup from "components/Rating/RatingPopup";
import {
  makerSubmitRating,
  errorMessageAlert,
  markListingRated,
  thankYou,
} from "functions/functions";
import ListingContext from "context/ListingContext";

function CompletedListing(props) {
  const listingId = props.listingId;
  const [isRated, setisRated] = useState(props.isRated);
  const [showPopup, setShowPopup] = useState(false);

  const navigate = useNavigate();
  const { setListingRated } = useContext(ListingContext);

  function rateTaker(reservation) {
    const submitRating = async (rating) => {
      try {
        await makerSubmitRating(rating, reservation);
        setShowPopup(false);
        markListingRated(listingId);
        setListingRated();
        setisRated(true);
        thankYou();
      } catch (error) {
        errorMessageAlert("failed to post your review");
      }
    };

    return (
      <>
        <div>
          <div className="rsv-helpfultext">
            You have yet to rate the Taker, please do so now;
          </div>
          <button
            className="button-primary wide reserve-btn"
            type="button"
            onClick={() => setShowPopup(true)}
          >
            {" "}
            Rate Taker{" "}
          </button>
        </div>
        {showPopup && (
          <RatingPopup
            submitRating={(rating) => submitRating(rating)}
            reservation={reservation}
            setShowPopup={setShowPopup}
            forTaker
          />
        )}
      </>
    );
  }

  if (props.isMaker) {
    const reservation = props.reservation;
    const name = reservation
      ? `${reservation?.taker.first_name} ${reservation?.taker.last_name}`
      : null;

    return (
      <div className="reserve-container">
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div className="rsv-header left"> You sold this listing! </div>{" "}
            <br />
            <div className="rsv-helpfultext">
              Congratulations on your sale with{" "}
              <button
                className="back small-btn listing-bold"
                type="button"
                onClick={() => navigate(`/user/${reservation.taker.uuid}`)}
              >
                {name}!
              </button>{" "}
            </div>
            <div className="rsv-helpfultext">
              {" "}
              Someday soon, youll be able to view the transaction&apos;s reciept
              here. Please bear with us as we add functionality.
            </div>
          </div>
          {isRated ? (
            <button
              className="button-terciary"
              type="button"
              onClick={() => {
                navigate("/create-listing");
              }}
            >
              {" "}
              Create another listing{" "}
            </button>
          ) : (
            rateTaker(reservation)
          )}
        </div>
      </div>
    );
  }
  if (props.isTaker) {
    return (
      <div className="reserve-container">
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div className="rsv-header left"> You bought this listing </div>{" "}
            <br />
            <div className="rsv-helpfultext">
              Congratulations on your purchase!
            </div>
            <div className="rsv-helpfultext">
              {" "}
              Someday soon, youll be able to view the transaction&apos;s reciept
              here. Please bear with us as we add functionality.
            </div>
          </div>
          <button
            className="button-terciary"
            type="button"
            onClick={() => {
              navigate("/");
            }}
          >
            {" "}
            Browse Jackrabbit Marketplace{" "}
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className="reserve-container">
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div className="rsv-header left">
            {" "}
            This listing has already been bought{" "}
          </div>{" "}
          <br />
          <div className="rsv-helpfultext">Sorry you missed out!</div>
          <div className="rsv-helpfultext">
            {" "}
            Material goes quick in the Jackrabbit Marketplace. If you see
            something you like, Jump on It!
          </div>
        </div>
        <button
          className="button-terciary"
          type="button"
          onClick={() => {
            navigate("/");
          }}
        >
          {" "}
          Browse Jackrabbit Marketplace{" "}
        </button>
      </div>
    </div>
  );
}

export default CompletedListing;
