import React, { useContext } from "react";
import { locationSplit } from "../../Marketplace/Listings/ListingFunctions";
import ListingContext from "../../../context/ListingContext";

function LocationDetails({ taker }) {
  const { currentListing, listingState } = useContext(ListingContext);

  function renderSwitch() {
    switch (listingState.isReserved || listingState.isOwner) {
      case true:
        return (
          <div className="location">
            <div className="product-details">
              <div className="item-desc left">
                <div className="rsv-subheader">
                  {" "}
                  Location{" "}
                  {currentListing.pickup_instructions &&
                    "and Pickup Instructions"}{" "}
                </div>
                <div style={{ textAlign: "left" }}>
                  <div className="rsv-helpfultext  left">
                    {" "}
                    {currentListing.location}{" "}
                  </div>
                  <br />
                  <div className="rsv-subheader"> Pickup Instructions </div>
                  <div className="rsv-helpfultext  left">
                    {" "}
                    {currentListing.pickup_instructions ||
                      "There are no pickup instructions"}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return (
          <div>
            <div className="item-desc left">
              <div className="rsv-subheader left">Location</div>
              <div className="rsv-helpfultext left">
                {" "}
                City: {locationSplit(currentListing.location)}{" "}
              </div>

              <div className="rsv-helpfultext left">
                {" "}
                Exact location and additional pickup instructions are shown upon
                reservation
              </div>
            </div>
          </div>
        );
    }
  }

  return (
    <div className={`location-container ${taker ? "lc-taker" : ""}`}>
      {" "}
      {renderSwitch()}{" "}
    </div>
  );
}

export default LocationDetails;
