import React, { useRef, useContext } from "react";
import UserContext from "context/UserContext";

function UserPictureSelector(props) {
  const { user } = useContext(UserContext);
  const containerRef = useRef(null);
  const addImage = props.addImage;
  const src = props.src ? props.src : user.image;

  const handleImageChange = (event) => {
    const image = event.target.files[0];
    addImage(image);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const image = event.dataTransfer.files[0];
    addImage(image);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <div
      className="image-uploader text-center image-uploader-circle"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      ref={containerRef}
    >
      <div>
        <label className="image-upload-label text-center" htmlFor="files">
          <div className="image-circle">
            <img className="image-previous" src={src} alt="" />{" "}
          </div>{" "}
          Drag and drop or click here to upload
        </label>
      </div>
      <input
        id="files"
        type="file"
        style={{ visibility: "hidden" }}
        onChange={handleImageChange}
        accept="image/*"
      />
    </div>
  );
}

export default UserPictureSelector;
