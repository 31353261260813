import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getChat, getUser, errorMessageAlert } from "functions/functions";
import "./MakerDetails.css";
import { Rating } from "react-simple-star-rating";
import { useMessage } from "context/MessageContext";
import axios from "axios";
import Userfront from "@userfront/react";
import ListingContext from "context/ListingContext";

function UserDetails(props) {
  const { chat, maker } = props;
  const [user, setUser] = useState();
  const { listingState, currentListing } = useContext(ListingContext);
  const id = maker
    ? currentListing?.reservations[0]?.taker.uuid
    : currentListing.maker;
  const { setCurrentChat } = useMessage();

  const navigate = useNavigate();

  function makerError() {
    setUser(undefined);
  }

  useEffect(() => {
    const fetchUser = async () => {
      if (id)
        try {
          const userData = await getUser(id);
          setUser(userData);
        } catch (error) {
          makerError();
        }
      else setUser("None");
    };
    fetchUser();
  }, []);

  const handleMessageClick = async () => {
    if (chat) setCurrentChat(chat);
    else {
      const cancelToken = axios.CancelToken.source();
      const takerId = maker ? user.userUuid : Userfront.user.userUuid;
      const makerId = maker ? Userfront.user.userUuid : user.userUuid;
      try {
        const response = await getChat(
          takerId,
          makerId,
          currentListing.listing_id,
          cancelToken.token
        );
        setCurrentChat(response);
      } catch (error) {
        if (!axios.isCancel(error)) {
          if (error.response && error.response.status !== 404) {
            errorMessageAlert("failed to message the user");
          }
        }
      }
    }
  };

  function renderSwitch() {
    switch (user) {
      case undefined:
        return (
          <div className="maker-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "flex-start",
                gap: "15px",
                height: "80px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingLeft: "10px",
                }}
              >
                <div className="rsv-subheader left"> Unknown User </div>
                <div className="rsv-helpfultext" style={{ textAlign: "left" }}>
                  There was probably an error{" "}
                </div>
              </div>
            </div>
          </div>
        );
      case "None":
        return (
          <div className="maker-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "flex-start",
                gap: "15px",
                height: "80px",
              }}
            >
              <img
                className="maker-image"
                src="https://res.cloudinary.com/component/image/upload/avatars/avatar-plain-16.png"
                alt="User"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingLeft: "10px",
                }}
              >
                <div className="rsv-subheader left"> No one is reserved </div>
                <div className="rsv-helpfultext" style={{ textAlign: "left" }}>
                  If someone reserves, we will send you an email.{" "}
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className={`maker-container ${maker ? "mc-orange" : ""}`}>
            <div className="rsv-header"> {maker && "Reserved by"} </div>
            <div className="user-details">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "flex-start",
                  gap: "15px",
                  width: "57%",
                }}
              >
                <img className="maker-image" src={user.image} alt="User" />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <button
                    className="rsv-subheader chat-header left five-mb"
                    type="button"
                    onClick={() =>
                      listingState.isLoggedIn === true &&
                      navigate(`/user/${user.userUuid}`)
                    }
                  >
                    {" "}
                    {user.name}{" "}
                  </button>
                  <div
                    className="rsv-helpfultext"
                    style={{ textAlign: "left" }}
                  >
                    {" "}
                    {user.data.rating === 0 ? (
                      "No rating"
                    ) : (
                      <Rating
                        size="20px"
                        readonly="true"
                        initialValue={
                          Number.isNaN(
                            Math.round(user.data.maker_rating * 100) / 100
                          )
                            ? 0
                            : Math.round(user.data.maker_rating * 100) / 100
                        }
                        allowFraction="true"
                      />
                    )}{" "}
                  </div>
                </div>
              </div>
              {Userfront.user.userUuid &&
              user.userUuid !== Userfront.user.userUuid ? (
                !chat ? (
                  <button
                    type="button"
                    className="button-terciary"
                    onClick={() => {
                      handleMessageClick();
                    }}
                  >
                    Message
                  </button>
                ) : (
                  <button
                    type="button"
                    className="continue-messaging"
                    onClick={() => {
                      handleMessageClick();
                    }}
                  >
                    <div className="bolder">Continue messaging</div>
                    <div className="content">
                      {chat?.most_recent_message?.sender !==
                      Userfront.user.userUuid ? (
                        <div className="orange-dot" />
                      ) : (
                        <div className="orange-dot white" />
                      )}
                      {chat.most_recent_message.content}
                    </div>
                  </button>
                )
              ) : (
                ""
              )}
            </div>
          </div>
        );
    }
  }
  return renderSwitch();
}

export default UserDetails;
