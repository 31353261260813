import React, { useContext } from "react";
import UserContext from "context/UserContext";
import { becomeMakerAlert, getOnboardingLink } from "functions/functions";
import Userfront from "@userfront/react";
import { makerSvg } from "Business/svg";
import DropDownOption from "./DropdownOption";

function MakerButton() {
  const { user } = useContext(UserContext);

  const thenFunction = async () => {
    navigator.clipboard.writeText(
      `www.jackrabt.com/user/${Userfront.user.userUuid}`
    );
    const onboardingLink = await getOnboardingLink();
    window.location.href = onboardingLink;
  };

  if (user.data && !user.data.isMaker)
    return (
      <DropDownOption
        key="switch"
        option={{
          name: `Start Selling`,
          svg: makerSvg(),
        }}
        clickHandler={() => becomeMakerAlert(thenFunction)}
      />
    );
  return "";
}

export default MakerButton;
