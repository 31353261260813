import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import RouteContext from "../../context/RouteContext";
import "./BreadCrumb.css";

function BreadCrumb() {
  const { urls, popUrl } = useContext(RouteContext);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  // Takes a path: /listings or /dashboard/your-reservations
  // Returns the text after the last slash "/"
  // All instances of "-" are replaced with a space
  function getLastWordFromRoute(route) {
    if (route === null) return "";
    const regex = /\/([\w-]+)$/;
    const match = regex.exec(route);
    const lastWord = match ? match[1] : "";
    // Check if the route is an integer
    if (/^\d+$/.test(lastWord)) {
      return "their listing";
    }

    // Check if the route is a string UUID
    if (
      /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/.test(
        lastWord
      )
    ) {
      return "their profile";
    }
    return lastWord.replace(/-/g, " ");
  }

  useEffect(() => {
    setMessage(urls.conditionalText || getLastWordFromRoute(urls.previous));
  }, [urls]);

  return (
    <label className="big-container" htmlFor="button">
      <div className="bc-icon">
        <svg
          width="18"
          height="20"
          viewBox="0 0 13 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.6707 20.0316C11.0748 20.6275 10.1086 20.6275 9.51266 20.0316L1.11975 11.6387C0.523813 11.0427 0.523813 10.0765 1.11975 9.48059L9.51266 1.08768C10.1086 0.491741 11.0748 0.491741 11.6707 1.08768C12.2667 1.68361 12.2667 2.64981 11.6707 3.24574L4.35685 10.5596L11.6707 17.8735C12.2667 18.4694 12.2667 19.4356 11.6707 20.0316Z"
            fill="#484848"
          />
        </svg>
      </div>
      <div id="container" className="bc-container">
        <div className="bc-message">
          <button
            id="button"
            className="bc-button"
            type="button"
            onClick={() => {
              navigate(`${urls.previous === null ? "/" : urls.previous}`);
              popUrl();
              popUrl();
            }}
          >
            {`Back to ${message === "" ? "Marketplace" : message}`}
          </button>
        </div>
      </div>
    </label>
  );
}

export default BreadCrumb;
