import DeleteListing from "./DeleteListing";

function listingTopBar(props) {
  const { active, setActive } = props;

  return (
    <div className="ltb-wrapper">
      <div className="listing-top-bar">
        <div className="ltb-container">
          <button
            type="button"
            className="ltb-button ltb-main"
            onClick={() => setActive("home")}
          >
            Your Listing
          </button>

          <div className="ltb-buttons">
            <button
              type="button"
              className={`ltb-button ${
                active === "Messages" ? `ltb-active` : ""
              }`}
              onClick={() => setActive("Messages")}
            >
              Messages
            </button>
            <button
              type="button"
              className={`ltb-button ${active === "Edit" ? `ltb-active` : ""}`}
              onClick={() => setActive("Edit")}
            >
              Edit
            </button>
            <DeleteListing setActive={setActive} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default listingTopBar;
