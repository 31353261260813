import React, { useState, createContext, useEffect } from "react";
import { getUser, errorMessageAlert } from "functions/functions";
import Userfront from "@userfront/react";

const UserContext = createContext();
export default UserContext;

export function UserProvider(props) {
  const [user, setUser] = useState({});
  const [loadedUser, setLoadedUser] = useState(false);

  const fetchUser = async () => {
    if (Userfront.tokens.accessToken)
      try {
        const userData = await getUser(Userfront.user.userId);
        setUser(userData);
        setLoadedUser(true);
      } catch (error) {
        errorMessageAlert("failed to load your account");
      }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const updateUser = () => {
    fetchUser();
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <UserContext.Provider value={{ user, loadedUser, updateUser }}>
      {props.children}
    </UserContext.Provider>
  );
}
