import React, { useState, useEffect, useContext } from "react";
import ListingContext from "context/ListingContext";

const NO_IMAGE_URL = process.env.REACT_APP_NO_IMAGE_URL;

function ImageDisplayer() {
  const { currentListing } = useContext(ListingContext);
  const images = JSON.parse(currentListing.listing_images);

  const [index, setIndex] = useState(0);

  const handleKeyPress = (event) => {
    if (event.key === "ArrowLeft") {
      if (index > 0) setIndex(index - 1);
    }
    if (event.key === "ArrowRight") {
      if (index < images.length - 1) setIndex(index + 1);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [index, images]);

  function arrowSVG(direction) {
    if (direction === "left") {
      return (
        <svg
          width="30"
          height="21"
          viewBox="0 0 13 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.6707 20.0316C11.0748 20.6275 10.1086 20.6275 9.51266 20.0316L1.11975 11.6387C0.523813 11.0427 0.523813 10.0765 1.11975 9.48059L9.51266 1.08768C10.1086 0.491741 11.0748 0.491741 11.6707 1.08768C12.2667 1.68361 12.2667 2.64981 11.6707 3.24574L4.35685 10.5596L11.6707 17.8735C12.2667 18.4694 12.2667 19.4356 11.6707 20.0316Z"
            fill="#484848"
          />
        </svg>
      );
    }
    return (
      <svg
        width="30"
        height="21"
        viewBox="0 0 13 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: "rotate(180deg)" }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6707 20.0316C11.0748 20.6275 10.1086 20.6275 9.51266 20.0316L1.11975 11.6387C0.523813 11.0427 0.523813 10.0765 1.11975 9.48059L9.51266 1.08768C10.1086 0.491741 11.0748 0.491741 11.6707 1.08768C12.2667 1.68361 12.2667 2.64981 11.6707 3.24574L4.35685 10.5596L11.6707 17.8735C12.2667 18.4694 12.2667 19.4356 11.6707 20.0316Z"
          fill="#484848"
        />
      </svg>
    );
  }

  function renderDots() {
    return images.map((_, i) => (
      <div key={_} className={`dot ${index === i ? "active-dot" : ""}`} />
    ));
  }

  function leftArrow() {
    if (index === 0) {
      return (
        <div className="arrow-left arrow arrow-disabled">
          {" "}
          <button className="arrow-button" type="button">
            {arrowSVG("left")}
          </button>
        </div>
      );
    }
    return (
      <div className="arrow-left arrow arrow-hover">
        <button
          className="arrow-button"
          type="button"
          onClick={() => setIndex(index - 1)}
        >
          {arrowSVG("left")}
        </button>
      </div>
    );
  }

  function rightArrow() {
    if (index === images.length - 1) {
      return (
        <div className="arrow-right arrow arrow-disabled">
          {" "}
          <button className="arrow-button" type="button">
            {arrowSVG("right")}
          </button>{" "}
        </div>
      );
    }
    return (
      <div className="arrow-right arrow arrow-hover">
        <button
          className="arrow-button"
          type="button"
          onClick={() => setIndex(index + 1)}
        >
          {arrowSVG("right")}
        </button>
      </div>
    );
  }

  return (
    <div className="image-container">
      <img
        className="listings-image"
        src={images[0] ? images[index] : NO_IMAGE_URL}
        alt=""
        loading="eager"
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          gap: "8px",
          width: "97%",
          marginTop: "-100px",
        }}
      >
        {images.length > 1 && (
          <>
            <div
              className="dots-container"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: "5px",
              }}
            >
              {renderDots()}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              {leftArrow()}
              {rightArrow()}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ImageDisplayer;
