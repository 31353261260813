import React, { useState } from "react";
import "./FormSteps.css";

const Step12 = ({
  answers,
  errors,
  editAnswers,
  setErrors,
  setStep,
  isValid,
}) => {
  const [activeButton, setIsActiveOne] = useState("");
  const handleClick = (button) => {
    setIsActiveOne(button);
    if (button === "one") editAnswers("quantity", 1);
    if (button === "many") {
      editAnswers("quantity", "");
      document.getElementById("quantity").focus();
    }
  };
  const clickBack = () => {
    setStep((prev) => prev - 1);
  };
  const clickNext = () => {
    if (isValid(answers, setErrors)) {
      setStep((prev) => prev + 1);
    }
  };

  return (
    <div className="create-listing-container sans" id="step12">
      <div className="reg-label">Tell us about your listing</div>
      <div className="reg-label-2" htmlFor="quantity">
        How many are you selling?
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
          marginTop: "10px",
        }}
      >
        <button
          type="button"
          className={`qty-btn ${
            activeButton === "one" || answers.quantity === 1
              ? "qty-btn-active"
              : ""
          }`}
          id="one"
          onClick={() => handleClick("one")}
          style={{ marginTop: "5px" }}
        >
          One
        </button>
        <div className="row">
          <button
            type="button"
            className={`qty-btn ${
              activeButton === "many" ||
              (answers.quantity !== "" && answers.quantity !== 1)
                ? "qty-btn-active qty-btn-many-active"
                : ""
            }`}
            id="many"
            onClick={() => handleClick("many")}
            style={{ marginTop: "5px" }}
          >
            Many
          </button>
          <div
            className={`input-hidder ${
              activeButton === "many" ||
              (answers.quantity !== "" && answers.quantity !== 1)
                ? "input-hidder-active"
                : ""
            }`}
          >
            <input
              className="reg-input number-input"
              id="quantity"
              onChange={(e) => editAnswers("quantity", e.target.value)}
              value={answers.quantity}
              placeholder="enter amount"
            />
          </div>
        </div>
      </div>
      <div className="error-message">{errors.quantity}</div>
      <div className="reg-label-2" htmlFor="unit_price">
        How much are you selling it for?
        <div style={{ position: "relative", width: "35%" }}>
          <span
            style={{
              position: "absolute",
              left: "10px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            $
          </span>
          <input
            className="reg-input number-input"
            id="unit_price"
            onChange={(e) => editAnswers("total_price", e.target.value)}
            value={answers.total_price}
            style={{ paddingLeft: "25px" }}
          />
        </div>
      </div>
      <div className="error-message">{errors.total_price}</div>
      <div className="button-row">
        <button
          className="button-terciary reserve-confirm-btn left-side"
          type="button"
          onClick={clickBack}
        >
          Back
        </button>
        <button
          className="button-primary reserve-confirm-btn right-side"
          type="button"
          onClick={clickNext}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default Step12;
