import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useMessage } from "context/MessageContext";
import { LoadingAnimation } from "Business/Animations";
import MessagesPopulator from "./MessagesPopulator";
import MessageSender from "./MessageSender";

function OpenChat({ updateChatOnNewMessage }) {
  const { currentChat, setCurrentChat, messages, otherPerson } = useMessage();
  const navigate = useNavigate();

  const variants = {
    hidden: { x: "100%" },
    visible: {
      x: "0%",
      transition: {
        duration: 0.4,
        type: "tween",
        stiffness: 300,
        ease: "easeInOut",
      },
    },
  };

  if (currentChat && otherPerson) {
    return (
      <AnimatePresence>
        <motion.div
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={variants}
          className="calendar-day-open message-open"
          layout
        >
          <div className="open-day-row">
            <div className="chat-row">
              <img
                className="maker-image chat-image"
                src={otherPerson.image}
                alt="User"
              />
              <div className="chat-header-column">
                <button
                  type="button"
                  className="day-header-open chat-header"
                  onClick={() => navigate(`/user/${otherPerson.userUuid}`)}
                >
                  {otherPerson.name}
                </button>
                <button
                  type="button"
                  onClick={() => navigate(`/listings/${currentChat.listing}`)}
                  className="chat-subheader"
                >
                  {currentChat.listing_name}
                </button>
              </div>
            </div>
            <button
              type="button"
              className="close-btn"
              onClick={() => setCurrentChat(null)}
            >
              {" "}
              x{" "}
            </button>
          </div>
          {!messages ? (
            <LoadingAnimation />
          ) : (
            <MessagesPopulator messages={messages} />
          )}
          <MessageSender updateChatOnNewMessage={updateChatOnNewMessage} />
        </motion.div>
      </AnimatePresence>
    );
  }
}

export default OpenChat;
