import Userfront from "@userfront/react";
import { useEffect, useRef, useContext } from "react";
import { formatTimestamp } from "functions/functions";
import UserContext from "context/UserContext";

function MessagesPopulator({ messages }) {
  const messagesEndRef = useRef(null);
  const { user } = useContext(UserContext);

  function compareUuid(id) {
    if (id === Userfront.user.userUuid) return true;
    return false;
  }
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  function messageView(message, self) {
    return (
      <div>
        <div
          className={`message ${self ? "self-message" : ""} ${
            user?.data?.state === "Maker" ? "message-dark" : ""
          }`}
        >
          {message.content}
        </div>
        <div className={`message-time ${self ? "time-self" : ""}`}>
          {formatTimestamp(message.timestamp)}{" "}
        </div>
      </div>
    );
  }

  return (
    <div className="messages-container">
      {messages.map((message) =>
        messageView(message, compareUuid(message.sender))
      )}
      <div ref={messagesEndRef} />
    </div>
  );
}

export default MessagesPopulator;
