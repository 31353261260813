import React, { useState } from "react";
import Userfront from "@userfront/react";
import { formatDateMonthYear } from "functions/functions";
import UserRating from "./UserRating";
import "./AccountHeader.css";
import UserPicturePopup from "../ProfilePicChanger/UserPicturePopup";

function AccountHeader(props) {
  const user = props.user === undefined ? Userfront.user : props.user;
  const self = props.self;
  const showReviews = props.showReviews;
  const setShowReviews = props.setShowReviews;
  const reviewCount = props.reviewCount;
  const [isCopied, setCopied] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleButtonClick = () => {
    navigator.clipboard.writeText(`www.jackrabt.com/user/${user.userUuid}`);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const linkSvg = () => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="-3 -3 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 13C10.4295 13.5741 10.9774 14.0492 11.6066 14.3929C12.2357 14.7367 12.9315 14.9411 13.6467 14.9923C14.3618 15.0435 15.0796 14.9404 15.7513 14.6898C16.4231 14.4392 17.0331 14.0471 17.54 13.54L20.54 10.54C21.4508 9.59699 21.9548 8.33397 21.9434 7.02299C21.932 5.71201 21.4061 4.45794 20.4791 3.5309C19.5521 2.60386 18.298 2.07802 16.987 2.06663C15.676 2.05523 14.413 2.55921 13.47 3.47L11.75 5.18"
          stroke="#111111"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.0002 11C13.5707 10.4259 13.0228 9.95083 12.3936 9.60707C11.7645 9.26331 11.0687 9.05889 10.3535 9.00768C9.63841 8.95646 8.92061 9.05964 8.24885 9.31023C7.5771 9.56082 6.96709 9.95294 6.4602 10.46L3.4602 13.46C2.54941 14.403 2.04544 15.666 2.05683 16.977C2.06822 18.288 2.59407 19.5421 3.52111 20.4691C4.44815 21.3961 5.70221 21.922 7.01319 21.9334C8.32418 21.9448 9.58719 21.4408 10.5302 20.53L12.2402 18.82"
          stroke="#111111"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };
  return (
    <div className="bar-container">
      <div className="profile-left">
        {props.self ? (
          <label className="user-image" htmlFor="profile-button">
            <button
              id="profile-button"
              className="user-image-button"
              type="button"
              onClick={() => setShowPopup(true)}
            >
              {" "}
              Change{" "}
            </button>

            <img src={user.image} alt="User" />
          </label>
        ) : (
          <label
            className="user-image user-image-nohover"
            htmlFor="profile-button"
          >
            <img src={user.image} alt="User" />
          </label>
        )}

        <div>
          <div className="profile-subheader ps2">
            {" "}
            Since {formatDateMonthYear(user.createdAt)}{" "}
          </div>
          <div className="profile-header"> {user.name}</div>
          {props.self && (
            <div className="profile-subheader ps1"> {user.email} </div>
          )}
        </div>
      </div>

      <div className="profile-right">
        {user.data.isMaker && props.changeState && (
          <button
            type="button"
            className="button-primary profile-switcher-button"
            onClick={() => props.changeState()}
          >
            {" "}
            Show {props.state === 0 ? "Taker" : "Maker"} profile{" "}
          </button>
        )}
        <div className="row">
          {isCopied && <div className="copied-text">Profile URL Copied</div>}
          <button
            type="button"
            className="button-terciary square-btn"
            onClick={() => handleButtonClick()}
          >
            {linkSvg()}
          </button>
          {self && reviewCount > 0 && (
            <button
              className="button-terciary show-reviews-btn"
              type="button"
              onClick={() => setShowReviews(!showReviews)}
            >
              {showReviews ? "Hide" : "Show"} {reviewCount} Reviews
            </button>
          )}
          <UserRating user={user.data} state={props.state} self={self} />
        </div>
      </div>
      <UserPicturePopup showPopup={showPopup} setShowPopup={setShowPopup} />
    </div>
  );
}

export default AccountHeader;
