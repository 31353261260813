import React, { useContext } from "react";
import ListingContext from "context/ListingContext";
import Userfront from "@userfront/react";

function ReserveConfirm(props) {
  const { currentListing } = useContext(ListingContext);

  function reserveHeader() {
    return (
      <div className="reserve-header" style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div className="rsv-header left">
            Confirm cost, availability, and contact information
          </div>
          {props.backButton}
        </div>
      </div>
    );
  }

  function reserveBody() {
    return (
      <div className="rsv-cnfrm-body">
        <div className="rsv-helpfultext  left">
          cost{" "}
          <div className="rsv-header" style={{ display: "inline" }}>
            {" "}
            ${currentListing.unit_price}{" "}
          </div>
        </div>
        <div className="rsv-helpfultext  left">
          expected availability{" "}
          <div className="rsv-subheader" style={{ display: "inline" }}>
            {" "}
            {currentListing.dates[0]} - {currentListing.dates[1]}{" "}
          </div>
        </div>
        <div className="rsv-helpfultext">
          your email{" "}
          <div className="rsv-subheader" style={{ display: "inline" }}>
            {" "}
            {Userfront.user.email}{" "}
          </div>
        </div>
      </div>
    );
  }

  function confirmButton() {
    if (
      currentListing.quantity !== 0 &&
      currentListing.unit_price > 0 &&
      currentListing.unit_price <= 999999
    ) {
      return (
        <button
          type="button"
          className="button-primary wide reserve-btn bold"
          onClick={() => props.reserve()}
        >
          {" "}
          Confirm and checkout{" "}
        </button>
      );
    }
    if (currentListing.quantity !== 0 && currentListing.unit_price === 0) {
      return (
        <button
          type="button"
          className="button-primary wide reserve-btn bold"
          onClick={() => props.reserveFree()}
        >
          {" "}
          Confirm reservation{" "}
        </button>
      );
    }
    return null;
  }

  return (
    <div className="reserve-interior">
      {reserveHeader()}
      {reserveBody()}
      {confirmButton()}
    </div>
  );
}

export default ReserveConfirm;
