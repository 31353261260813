import { createContext, useState, useEffect } from "react";

const RouteContext = createContext();
export default RouteContext;

// A context to store the current and previous routes in a stack
export const RouteContextProvider = ({ children }) => {
  const [urlStack, setUrlStack] = useState([]);

  useEffect(() => {
    const storedUrlStack = localStorage.getItem("urlStack");
    if (storedUrlStack) {
      setUrlStack(JSON.parse(storedUrlStack));
    }
  }, []);

  const pushUrl = (url) => {
    setUrlStack((prevStack) => {
      const newUrlStack = [...prevStack, { url, conditionalText: "" }];
      localStorage.setItem("urlStack", JSON.stringify(newUrlStack));
      return newUrlStack;
    });
  };

  const updateConditionalText = (conditionalText) => {
    setUrlStack((prevStack) => {
      if (prevStack.length === 0) {
        return prevStack;
      }
      const lastUrl = prevStack[prevStack.length - 1];
      const updatedLastUrl = { ...lastUrl, conditionalText };
      const updatedUrlStack = [...prevStack.slice(0, -1), updatedLastUrl];
      localStorage.setItem("urlStack", JSON.stringify(updatedUrlStack));
      return updatedUrlStack;
    });
  };

  const popUrl = () => {
    setUrlStack((prevStack) => {
      const newUrlStack = prevStack.slice(0, -1);
      localStorage.setItem("urlStack", JSON.stringify(newUrlStack));
      return newUrlStack;
    });
  };

  // eslint-disable-next-line
  const contextValue = {
    urls: {
      previous: urlStack[urlStack.length - 2]?.url || null,
      conditionalText: urlStack[urlStack.length - 2]?.conditionalText || "",
    },
    pushUrl,
    popUrl,
    updateConditionalText,
  };

  return (
    <RouteContext.Provider value={contextValue}>
      {children}
    </RouteContext.Provider>
  );
};
