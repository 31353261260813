import { useEffect, useState } from "react";
import { getUser } from "functions/functions";
import { useMessage } from "context/MessageContext";
import Userfront from "@userfront/react";

function ChatPopulator({ chats }) {
  const { setCurrentChat } = useMessage();
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    async function fetchUserDetails() {
      const fetchPromises = chats.map((chat) => {
        const idToGet =
          Userfront.user.userUuid === chat.maker ? chat.taker : chat.maker;
        return getUser(idToGet)
          .then((userData) => ({
            id: idToGet,
            name: userData.name,
            image: userData.image,
          }))
          .catch(() => ({ id: idToGet, name: "Unknown" }));
      });

      const results = await Promise.all(fetchPromises);
      const newDetails = results.reduce((acc, { id, name, image }) => {
        acc[id] = { name, image };
        return acc;
      }, {});

      setUserDetails(newDetails);
    }

    fetchUserDetails();
  }, [chats]);

  function chatDisplayer(chat) {
    const idToGet =
      Userfront.user.userUuid === chat.maker ? chat.taker : chat.maker;
    const userDetail = userDetails[idToGet] || {
      name: "Loading...",
      image: "",
    };

    return (
      <button
        type="button"
        className="maker-container chat-container"
        key={chat.id}
        onClick={() => setCurrentChat(chat)}
      >
        {chat?.most_recent_message?.sender !== Userfront.user.userUuid ? (
          <div className="orange-dot" />
        ) : (
          <div className="orange-dot white" />
        )}
        {userDetail.image && (
          <img
            src={userDetail.image}
            alt={userDetail.name}
            className="maker-image chat-image"
          />
        )}
        <div className="message-column">
          <div className="maker-name message-name">{userDetail.name}</div>
          <div className="message-content">
            {chat?.most_recent_message?.content}
          </div>
        </div>
      </button>
    );
  }

  return <>{chats.map((chat) => chatDisplayer(chat))}</>;
}

export default ChatPopulator;
