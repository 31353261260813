import { motion } from "framer-motion";

function CalendarDay(props) {
  const { date, openDate, setOpenDay, listings, reservations, isToday } = props;
  const day = {
    openDate,
    listings,
    reservations,
    isToday,
  };

  function listingItem() {
    return (
      <>
        {listings.map((listing) => (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className={`listing-item ${
              listing.isPickupStart ? "item-start" : "item-end"
            }`}
            key={listing.id}
          >
            {listing.name}
          </motion.div>
        ))}
      </>
    );
  }

  function reservationItem() {
    return (
      <>
        {reservations.map((reservation) => (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className={`listing-item reservation-item ${
              reservation.isPickupStart ? "item-start" : "item-end"
            }`}
            key={reservation.id}
          >
            {reservation.listing_id.name}
          </motion.div>
        ))}
      </>
    );
  }

  return (
    <button
      type="button"
      key={date}
      className="calendar-day"
      onClick={
        listings.length >= 1 || reservations.length >= 1
          ? () => setOpenDay(day)
          : () => setOpenDay(false)
      }
    >
      <div className={`day-number ${isToday ? "day-today" : ""}`}>{date}</div>
      <div className="day-items">
        {listings && listings.length >= 1 && listingItem()}
        {reservations && reservations.length >= 1 && reservationItem()}
      </div>
    </button>
  );
}

export default CalendarDay;
