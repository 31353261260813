import ChatPopulator from "pages/Messages/ChatPopulator";
import { motion, AnimatePresence } from "framer-motion";

function MessagesOpen({ open, close, chats }) {
  const variants = {
    hidden: { x: "100%" },
    visible: {
      x: "0%",
      transition: {
        duration: 0.4,
        type: "tween",
        stiffness: 300,
        ease: "easeInOut",
      },
    },
  };

  if (open && chats)
    return (
      <AnimatePresence>
        <motion.div
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={variants}
          className="calendar-day-open message-open"
          layout
        >
          <div className="open-day-row">
            <div className="day-header-open">Messages</div>
            <button type="button" className="close-btn" onClick={() => close()}>
              {" "}
              x{" "}
            </button>
          </div>
          {chats.length > 0 ? (
            <ChatPopulator chats={chats} />
          ) : (
            <div>No messages for this listing</div>
          )}
        </motion.div>
      </AnimatePresence>
    );
}

export default MessagesOpen;
