import PuffLoader from "react-spinners/PuffLoader";
import PulseLoader from "react-spinners/PulseLoader";

export function LoadingAnimation() {
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <div>
        <PuffLoader size={150} color="#ff6708" speedMultiplier={0.7} />
      </div>
    </div>
  );
}

export function ButtonAnimation() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "16px",
        minWidth: "87px",
      }}
    >
      <PulseLoader size={10} color="#ffffff" speedMultiplier={0.5} />
    </div>
  );
}
