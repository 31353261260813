import React, { useEffect, useContext } from "react";
import ListingContext from "context/ListingContext";
import { getTextBeforeComma } from "../../../functions/functions";
// eslint-disable-next-line import/no-unresolved
import mapboxgl from "!mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const MAP_BOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

const MapBox = () => {
  const { currentListing, listingState } = useContext(ListingContext);

  const { isReserved, isOwner } = listingState;
  const lat = [
    currentListing.latitude ? currentListing.latitude : 0,
    currentListing.rand_latitude ? currentListing.rand_latitude : 0,
  ];
  const long = [
    currentListing.longitude ? currentListing.longitude : 0,
    currentListing.rand_longitude ? currentListing.rand_longitude : 0,
  ];
  const location = getTextBeforeComma(currentListing.location);

  useEffect(() => {
    mapboxgl.accessToken = MAP_BOX_TOKEN;
    const zoom = 14;
    const state = isReserved || isOwner ? 0 : 1;

    const map = new mapboxgl.Map({
      container: "map",
      center: [long[state], lat[state]],
      zoom,
      minZoom: 12,
      maxZoom: state === 1 ? 13 : 16.5,
      style: "mapbox://styles/mapbox/streets-v11",
      transformRequest: (url, resourceType) => {
        if (resourceType === "Source" && url.startsWith("http://myHost")) {
          return {
            headers: { "my-custom-header": true },
            credentials: "include", // Include cookies for cross-origin requests
          };
        }
        // Return null for other cases
        return null;
      },
    });

    // Create the orange circle
    const markerElement = document.createElement("div");
    markerElement.style.width = "200px";
    markerElement.style.height = "200px";
    markerElement.style.borderRadius = "50%";
    markerElement.style.backgroundColor =
      state === 1 ? "rgba(255, 103, 8, 0.8)" : "rgba(255, 103, 8, 0)";
    markerElement.style.position = "relative";

    // Create a container element for the text
    const textContainer = document.createElement("div");
    textContainer.style.position = "absolute";
    textContainer.style.top = "80%";
    textContainer.style.left = "50%";
    textContainer.style.transform =
      state === 1 ? "translate(-50%, -50%)" : "translate(-50%, -100%)";
    textContainer.style.backgroundColor = "rgba(255, 255, 255, 1)";
    textContainer.style.padding = "10px";
    textContainer.style.borderRadius = "2px";

    // Create the text element
    const textElement = document.createElement("p");
    textElement.textContent =
      long[0] === 0 && lat[0] === 0
        ? "Invalid Location Provided"
        : state === 1
        ? "Reserve to reveal location"
        : location;
    textElement.style.margin = "0";
    textElement.style.whiteSpace = "nowrap";
    textElement.style.overflow = "hidden";
    textElement.style.textOverflow = "ellipsis";
    textElement.style.fontSize = "16px";

    // Append the text element to the text container
    textContainer.appendChild(textElement);

    // Append the text container to the marker element
    markerElement.appendChild(textContainer);

    // Append the text element to the text container
    textContainer.appendChild(textElement);

    // Append the text container to the marker element
    markerElement.appendChild(textContainer);

    // Render marker circle for props.isReserved = 1
    const circle = new mapboxgl.Marker({
      element: markerElement,
    })
      .setLngLat([long[state], lat[state]])
      .addTo(map);
    // Render marker for props.isReserved = 0
    const marker = new mapboxgl.Marker({
      color: "#ff6708",
      scale: 1.5,
    })
      .setLngLat([long[state], lat[state]])
      .addTo(map);

    // Clean up the map instance when the component unmounts
    return () => {
      map.remove();
      if (marker) marker.remove();
      if (circle) circle.remove();
    };
  }, [isOwner, isReserved]);

  return <div id="map" className="map-div" />;
};

export default MapBox;
