import React from "react";

export const Step31 = ({
  answers,
  errors,
  editAnswers,
  setStep,
  isValid,
  setErrors,
}) => {
  const clickBack = () => {
    setStep((prev) => prev - 1);
  };

  const clickNext = () => {
    if (isValid(answers, setErrors)) {
      setStep((prev) => prev + 1);
    }
  };

  return (
    <div className="create-listing-container sans" id="step3">
      <div className="reg-label"> Market your listing </div>
      <div className="reg-label-2" htmlFor="title">
        Give it a short and descriptive title
      </div>
      <input
        className="reg-input"
        required
        id="title"
        onChange={(e) => editAnswers("title", e.target.value)}
        value={answers.title}
        placeholder=""
      />
      <div className="error-message">{errors.title}</div>

      <div className="reg-label-2" htmlFor="description">
        Write a description to help it sell
      </div>
      <textarea
        className="reg-text-area"
        id="description"
        onChange={(e) => editAnswers("description", e.target.value)}
        placeholder="Optional"
        value={answers.description}
      />

      <div className="button-row">
        <button
          className="button-terciary reserve-confirm-btn left-side"
          onClick={clickBack}
          type="button"
        >
          Back
        </button>
        <button
          className="button-primary reserve-confirm-btn right-side"
          type="button"
          onClick={clickNext}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default Step31;
