import React, { useEffect, useState, useRef } from "react";

function ProfileBodySelector(props) {
  const [activeId, setActiveId] = useState(0);
  const buttonRef = useRef(null);

  // To display reviews when maker profile is switched to taker profile with listings open
  useEffect(() => {
    buttonRef.current.click();
  }, [props.showTaker]);

  const onClick = (event) => {
    props.setView(event.currentTarget.id);

    if (activeId !== null) {
      document.getElementById(activeId).className = "rsv-filter";
    }
    setActiveId(event.currentTarget.id);
    document.getElementById(event.currentTarget.id).className =
      "rsv-filter rsv-filter-clicked";
  };

  return (
    <div className="margin-top-20px margin-bottom-12px">
      <div className="rsv-buttons left">
        <button
          type="button"
          className="rsv-filter rsv-filter-clicked"
          onClick={onClick}
          value={0}
          id={0}
          ref={buttonRef}
        >
          <span className="">
            {" "}
            {props.reviewCount
              ? props.reviewCount === 1
                ? `${props.reviewCount} Review`
                : `${props.reviewCount} Reviews`
              : "0 Reviews"}{" "}
          </span>
        </button>
        {"     "}
        {!props.showTaker ? (
          <button
            type="button"
            className="rsv-filter"
            onClick={onClick}
            value={1}
            id={1}
          >
            <span className="">
              {" "}
              {props.listingCount
                ? props.listingCount === 1
                  ? `${props.listingCount} Listing`
                  : `${props.listingCount} Listings`
                : "0 Listings"}{" "}
            </span>
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default ProfileBodySelector;
